import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/shop/order/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/shop/order/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/shop/order/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/shop/order/submit',
    method: 'post',
    data: row
  })
}

export const changeStatus = (row) => {
  return request({
    url: '/shop/order/changeStatus',
    method: 'post',
    data: row
  })
}

export const exportExcelOrder = (module, current, size, params) => {
  return request({
    url: module + '/exportOrder',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

