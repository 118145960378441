<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpenPre"
        @search-change="searchChangePre"
        @search-reset="searchResetPre"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="getPrePage">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportPre()">导出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { exportPreExcel, getPreList } from '@/api/bms/branchpurchaseitem'
import { formatDate } from '@/util/date'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchpurchaseitem',
      form: {},
      search: {},
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menu: false,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '预估采购单号',
            prop: 'sn',
            search: true,
            searchLabelWidth: 100,
            rules: [{
              required: true,
              message: '请输入单号'
            }]
          },
          {
            label: '预估采购日期',
            prop: 'createTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            editDisabled: true,
            addDisabled: true,
            search: true,
            searchLabelWidth: 100
          },
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            search: true
          },
          {
            label: '物料号',
            prop: 'materialFullCode'
          },
          {
            label: '品名',
            prop: 'materialName'
          },
          {
            label: '重量',
            prop: 'weight'
          }
        ]
      },
      data: []
    }
  },
  computed: {
  },
  methods: {
    beforeOpenPre (done, type) {
      if (['edit', 'view'].includes(type)) {
        getPreList(this.module, this.form.id).then(res => {
          this.form = res.data
        })
      }
      done()
    },
    getPrePage (page) {
      getPreList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    searchChangePre (params, done) {
      this.search = params
      this.getPrePage(this.page)
      done()
    },
    searchResetPre () {
      this.search = {}
      this.getPrePage(this.page)
    },
    exportPre () {
      this.handlePreExport('预估采购单信息导出')
    },
    handlePreExport (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportPreExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    }
  }
}
</script>
