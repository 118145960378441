<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportOrderItem">导出明细
        </el-button>
        <el-button
            type="success"
            @click.stop="exportOrder">导出订单
        </el-button>
      </template>
      <template  slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toView(row)">查看
        </el-button>
        <el-button
            type="text"
            icon="el-icon-check"
            size="small"
            @click.stop="toChange(row)">收货
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { changeStatus, exportExcelOrder } from '@/api/shop/order'
import FileSaver from 'file-saver'
import { formatDate } from '@/util/date'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/order',
      form: {},
      search: {
      },
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        menuWidth: 120,
        index: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 5,
        searchLabelWidth: 130,
        indexLabel: '序号',
        column: [
          {
            label: '是否当日单',
            prop: 'deliveryAtOnce',
            type: 'select',
            search: true,
            dicData: [{
              label: '是',
              value: 1
            }, {
              label: '否',
              value: 0
            }]
          },
          {
            label: '门店名称',
            prop: 'branchName',
            width: 100
          },
          {
            label: '订单编号',
            prop: 'sn',
            search: true,
            width: 150
          },
          {
            label: '下单时间',
            prop: 'paymentTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            search: true
          },
          {
            label: '订单类型',
            prop: 'clientType',
            type: 'select',
            filterable: true,
            search: true,
            dicUrl: '/sys/dict/code/order_client_type'
          },
          {
            label: '微信名',
            prop: 'nickName'
          },
          {
            label: '收件人姓名',
            prop: 'consigneeName'
          },
          {
            label: '收件人手机',
            prop: 'consigneeMobile'
          },
          {
            label: '收件人地址',
            prop: 'consigneeDetail',
            width: 200
          },
          {
            label: '商品数量',
            prop: 'goodsNum'
          },
          {
            label: '订单总价格',
            prop: 'flowPrice'
          },
          {
            label: '支付方式',
            prop: 'paymentMethod',
            dicUrl: '/sys/dict/code/order_payment_method'
          },
          {
            label: '配送方式',
            prop: 'deliveryMethod',
            dicUrl: '/sys/dict/code/order_delivery_method'
          },
          {
            label: '订单状态',
            prop: 'orderStatus',
            search: true,
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dict/code/order_order_status'
          },
          {
            label: '客户预约送达时间',
            prop: 'deliveryTime',
            width: 160
          },
          {
            label: '货运状态',
            prop: 'deliverStatus',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dict/code/order_deliver_status'
          },
          {
            label: '优惠的金额',
            prop: 'discountPrice'
          },
          {
            label: '使用的优惠券名称',
            prop: 'couponName'
          },
          {
            label: '优惠券描述',
            prop: 'description'
          },
          {
            label: '优惠券面额',
            prop: 'couponPrice'
          },
          {
            label: '买家订单备注',
            prop: 'remark'
          },
          {
            label: '推荐人手机号',
            prop: 'referrerMobile',
            search: true
          },
          {
            label: '后台备注',
            prop: 'backRemark'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_order_add,
        viewBtn: this.permission.shop_order_view,
        delBtn: this.permission.shop_order_delete,
        editBtn: this.permission.shop_order_edit
      }
    }
  },
  methods: {
    toView (row) {
      this.$router.push({
        path: '/shop/orderitem',
        query: { sn: row.sn }
      })
    },
    toChange (row) {
      this.$confirm('确认收货吗？', '提示', {}).then(() => {
        changeStatus(row).then(() => {
          this.onLoad(this.page)
          this.$message({
            type: 'success',
            message: '收货成功!'
          })
        })
      })
    },
    exportOrderItem () {
      if (this.selectionList.length > 0) {
        this.search.snList = this.selectionList.map(item => item.sn).join(',')
      } else {
        this.search.snList = null
      }
      this.handleExport('线上订单明细导出')
    },
    exportOrder () {
      this.handleExportOrder('线上订单导出')
    },
    handleExportOrder (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportExcelOrder(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    }
  }
}
</script>
