<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/memberevaluation',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        menuWidth: 140,
        delBtn: false,
        addBtn: false,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '订单号',
            prop: 'orderNo',
            editDisabled: true,
            search: true
          },
          {
            label: '商品名称',
            prop: 'goodsName',
            search: true,
            editDisabled: true
          },
          {
            label: '评价内容',
            prop: 'content',
            editDisabled: true
          },
          {
            label: '好中差评',
            prop: 'grade',
            type: 'select',
            search: true,
            dicData: [{
              label: '好评',
              value: 'GOOD'
            },
            {
              label: '中评',
              value: 'MODERATE'
            },
            {
              label: '差评',
              value: 'WORSE'
            }],
            editDisabled: true
          },
          {
            label: '评论时间',
            prop: 'createTime',
            width: 130,
            editDisabled: true
          },
          {
            label: '商品图片',
            prop: 'goodsImage',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            oss: 'ali',
            editDisabled: true
          },
          {
            label: '评价图片',
            prop: 'images',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            oss: 'ali',
            editDisabled: true
          },
          {
            label: '会员名称',
            prop: 'memberName',
            editDisabled: true
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            dicData: [{
              label: '关闭',
              value: 'CLOSE'
            },
            {
              label: '开启',
              value: 'OPEN'
            }],
            editDisabled: true
          },
          {
            label: '回复状态',
            prop: 'replyStatus',
            type: 'select',
            search: true,
            dicData: [{
              label: '已回复',
              value: true
            },
            {
              label: '未回复',
              value: false
            }],
            editDisabled: true
          },
          {
            label: '物流评分',
            prop: 'deliveryScore',
            editDisabled: true
          },
          {
            label: '描述评分',
            prop: 'descriptionScore',
            editDisabled: true
          },
          {
            label: '服务评分',
            prop: 'serviceScore',
            editDisabled: true
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            editDisabled: true
          },
          {
            label: '评价回复',
            prop: 'reply',
            width: 500,
            type: 'textarea',
            span: 24,
            rules: [{
              required: true,
              message: '请输入评价回复'
            }]
          },
          {
            label: '评价回复图片',
            prop: 'replyImage',
            type: 'upload',
            listType: 'picture-card',
            dataType: 'string',
            oss: 'ali',
            span: 24
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_memberevaluation_add,
        viewBtn: this.permission.shop_memberevaluation_view,
        delBtn: this.permission.shop_memberevaluation_delete,
        editBtn: this.permission.shop_memberevaluation_edit
      }
    }
  },
  methods: {
  }
}
</script>
