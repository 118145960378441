<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_branchconfigdetail_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchconfigdetail',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: 'ID',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入ID'
            }]
          },
          {
            label: '门店配置记录主键',
            prop: 'configId',
            rules: [{
              required: true,
              message: '请输入门店配置记录主键'
            }]
          },
          {
            label: '类型，0：营业时间；1：配送时间；2：自提时间，3：当天配送订单的退款截止时间',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入类型，0：营业时间；1：配送时间；2：自提时间，3：当天配送订单的退款截止时间'
            }]
          },
          {
            label: '起始时间',
            prop: 'start',
            rules: [{
              required: true,
              message: '请输入起始时间'
            }]
          },
          {
            label: '结束时间',
            prop: 'end',
            rules: [{
              required: true,
              message: '请输入结束时间'
            }]
          },
          {
            label: '创建者',
            prop: 'createBy',
            rules: [{
              required: true,
              message: '请输入创建者'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '更新者',
            prop: 'updateBy',
            rules: [{
              required: true,
              message: '请输入更新者'
            }]
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入更新时间'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchconfigdetail_add,
        viewBtn: this.permission.bms_branchconfigdetail_view,
        delBtn: this.permission.bms_branchconfigdetail_delete,
        editBtn: this.permission.bms_branchconfigdetail_edit
      }
    }
  },
  methods: {
  }
}
</script>
