<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        >
      <template slot="menuLeft">
        <el-button type="success" @click="toBranchOrder">返回</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getView } from '@/api/bms/branchorder'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchorder',
      form: {},
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        menu: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        column: [
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            width: 100,
            disabled: true
          },
          {
            label: '订单编号',
            prop: 'orderSn',
            width: 180,
            disabled: true
          },
          {
            label: '单价',
            prop: 'materialPrice',
            disabled: true
          },
          {
            label: '重量',
            prop: 'weight',
            disabled: true
          },
          {
            label: '小计',
            prop: 'averagePrice',
            disabled: true
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            filterable: true,
            disabled: true,
            dicData: [{
              label: '现金',
              value: 0
            },
            {
              label: '动态聚合码',
              value: 1
            }]
          },
          {
            label: '订单状态',
            prop: 'status',
            type: 'select',
            filterable: true,
            disabled: true,
            dicData: [{
              label: '待付款',
              value: 0
            },
            {
              label: '已支付',
              value: 2
            },
            {
              label: '已关闭',
              value: 3
            }, {
              label: '无效订单',
              value: 4
            }]
          },
          {
            label: '支付时间',
            prop: 'paymentTime',
            hide: true,
            disabled: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            hide: true,
            addDisabled: true,
            editDisabled: true,
            disabled: true
          },
          {
            label: '牛肉耳标',
            prop: 'earTag',
            width: 100,
            disabled: true
          },
          {
            label: '物料名称',
            prop: 'materialName',
            disabled: true
          },
          {
            label: '图片',
            prop: 'materialLogo',
            disabled: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '折扣',
            prop: 'discount'
          },
          {
            label: '内购价格',
            prop: 'innerUnitPrice'
          },
          {
            label: '是否赠品',
            prop: 'isGift',
            type: 'select',
            dicData: [{
              label: '是',
              value: 1
            }, {
              label: '否',
              value: 0
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.bms_branchorder_view
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    toBranchOrder () {
      this.$router.push({ path: '/bms/branchorder' })
    },
    async loadData () {
      const res = await getView(this.form.id)
      this.data = res.data
    }
  }
}
</script>
