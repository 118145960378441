<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_draftgoods_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/draftgoods',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '品牌ID',
            prop: 'brandId',
            rules: [{
              required: true,
              message: '请输入品牌ID'
            }]
          },
          {
            label: '购买数量',
            prop: 'buyCount',
            rules: [{
              required: true,
              message: '请输入购买数量'
            }]
          },
          {
            label: '分类名称json',
            prop: 'categoryNameJson',
            rules: [{
              required: true,
              message: '请输入分类名称json'
            }]
          },
          {
            label: '分类path',
            prop: 'categoryPath',
            rules: [{
              required: true,
              message: '请输入分类path'
            }]
          },
          {
            label: '评论数量',
            prop: 'commentNum',
            rules: [{
              required: true,
              message: '请输入评论数量'
            }]
          },
          {
            label: '成本价格',
            prop: 'cost',
            rules: [{
              required: true,
              message: '请输入成本价格'
            }]
          },
          {
            label: '可用库存',
            prop: 'enableQuantity',
            rules: [{
              required: true,
              message: '请输入可用库存'
            }]
          },
          {
            label: '运费承担者',
            prop: 'freightPayer',
            rules: [{
              required: true,
              message: '请输入运费承担者'
            }]
          },
          {
            label: '商品图片json',
            prop: 'goodsGalleryListJson',
            rules: [{
              required: true,
              message: '请输入商品图片json'
            }]
          },
          {
            label: '商品名称',
            prop: 'goodsName',
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '商品参数json',
            prop: 'goodsParamsListJson',
            rules: [{
              required: true,
              message: '请输入商品参数json'
            }]
          },
          {
            label: '计量单位',
            prop: 'goodsUnit',
            rules: [{
              required: true,
              message: '请输入计量单位'
            }]
          },
          {
            label: '商品视频',
            prop: 'goodsVideo',
            rules: [{
              required: true,
              message: '请输入商品视频'
            }]
          },
          {
            label: '商品好评率',
            prop: 'grade',
            rules: [{
              required: true,
              message: '请输入商品好评率'
            }]
          },
          {
            label: '商品详情',
            prop: 'intro',
            rules: [{
              required: true,
              message: '请输入商品详情'
            }]
          },
          {
            label: '商品移动端详情',
            prop: 'mobileIntro',
            rules: [{
              required: true,
              message: '请输入商品移动端详情'
            }]
          },
          {
            label: '原图路径',
            prop: 'original',
            rules: [{
              required: true,
              message: '请输入原图路径'
            }]
          },
          {
            label: '商品价格',
            prop: 'price',
            rules: [{
              required: true,
              message: '请输入商品价格'
            }]
          },
          {
            label: '库存',
            prop: 'quantity',
            rules: [{
              required: true,
              message: '请输入库存'
            }]
          },
          {
            label: '是否为推荐商品',
            prop: 'recommend',
            rules: [{
              required: true,
              message: '请输入是否为推荐商品'
            }]
          },
          {
            label: '销售模式',
            prop: 'salesModel',
            rules: [{
              required: true,
              message: '请输入销售模式'
            }]
          },
          {
            label: '草稿商品保存类型',
            prop: 'saveType',
            rules: [{
              required: true,
              message: '请输入草稿商品保存类型'
            }]
          },
          {
            label: '是否自营',
            prop: 'selfOperated',
            rules: [{
              required: true,
              message: '请输入是否自营'
            }]
          },
          {
            label: '店铺ID',
            prop: 'storeId',
            rules: [{
              required: true,
              message: '请输入店铺ID'
            }]
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '卖点',
            prop: 'sellingPoint',
            rules: [{
              required: true,
              message: '请输入卖点'
            }]
          },
          {
            label: '店铺分类',
            prop: 'shopCategoryPath',
            rules: [{
              required: true,
              message: '请输入店铺分类'
            }]
          },
          {
            label: '货品列表json',
            prop: 'skuListJson',
            rules: [{
              required: true,
              message: '请输入货品列表json'
            }]
          },
          {
            label: '小图路径',
            prop: 'small',
            rules: [{
              required: true,
              message: '请输入小图路径'
            }]
          },
          {
            label: '商品编号',
            prop: 'sn',
            rules: [{
              required: true,
              message: '请输入商品编号'
            }]
          },
          {
            label: '运费模板ID',
            prop: 'templateId',
            rules: [{
              required: true,
              message: '请输入运费模板ID'
            }]
          },
          {
            label: '缩略图',
            prop: 'thumbnail',
            rules: [{
              required: true,
              message: '请输入缩略图'
            }]
          },
          {
            label: '重量',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入重量'
            }]
          },
          {
            label: '店铺分类路径',
            prop: 'storeCategoryPath',
            rules: [{
              required: true,
              message: '请输入店铺分类路径'
            }]
          },
          {
            label: '大图路径',
            prop: 'big',
            rules: [{
              required: true,
              message: '请输入大图路径'
            }]
          },
          {
            label: '',
            prop: 'marketEnable',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '',
            prop: 'goodsType',
            rules: [{
              required: true,
              message: '请输入'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_draftgoods_add,
        viewBtn: this.permission.shop_draftgoods_view,
        delBtn: this.permission.shop_draftgoods_delete,
        editBtn: this.permission.shop_draftgoods_edit
      }
    }
  },
  methods: {
  }
}
</script>
