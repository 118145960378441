<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="getListMaterialBomDTO">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-back"
            plain
            @click="toBack()">返回
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { actPage } from '@/api/vms/materialbom'

export default {
  mixins: [mixin],
  data () {
    return {
      MaterialBomDTO: {
        needDate: null
      },
      module: 'vms/materialbom',
      form: {},
      search: {},
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        menu: false,
        delBtn: false,
        editBtn: false,
        viewBtn: false,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '物料号',
            prop: 'fullCode',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '品名',
            prop: 'id',
            type: 'select',
            filterable: true,
            dicUrl: '/vms/materialbom/material'
          },
          {
            label: '参考重量（公斤）',
            prop: 'weight',
            rules: [{
              required: true,
              message: '请输入参考重量（公斤）'
            }]
          },
          {
            label: '需要重量（公斤）',
            prop: 'purchaseWeight',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '参考牛头数',
            prop: 'cattleNum',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '到店日期',
            prop: 'needDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            searchLabelWidth: 100,
            hide: true,
            addDisplay: false,
            editDisplay: false
          }
        ]
      },
      data: [],
      params: {}
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_materialbom_add,
        viewBtn: this.permission.vms_materialbom_view,
        delBtn: this.permission.vms_materialbom_delete,
        editBtn: this.permission.vms_materialbom_edit
      }
    }
  },
  created () {
    this.form.needDate = this.$route.query.needDate
    if (this.form.needDate) {
      this.getListMaterialBomDTO()
    }
  },
  methods: {
    async getListMaterialBomDTO () {
      this.MaterialBomDTO.needDate = this.form.needDate
      const res = await actPage(this.MaterialBomDTO)
      this.data = res.data
    },
    toBack () {
      this.$router.push({ path: '/vms/bomcattleact' })
    }
  }

}
</script>
