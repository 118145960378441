import { request } from '@/util/http'

export const getBranch = (current, size, params) => {
  return request({
    url: '/shop/orderdetailexport/pageBranch',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const exportBranch = (current, size, params) => {
  return request({
    url: '/shop/orderdetailexport/exportBranch',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

