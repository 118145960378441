<template>
  <basic-container>
    <div class="end" style="margin-bottom: 15px">
      <el-button
          type="success"
          size="small"
          @click.stop="toSubmit()">提交
      </el-button>
      <el-button
          type="primary"
          size="small"
          @click.stop="toBack()">返回
      </el-button>
    </div>
    <avue-form ref="form" :option="option" v-model="form">
    </avue-form>
  </basic-container>
</template>

<script>
import { getLast, submitLast } from '@/api/bms/branchpurchase'

export default {
  data () {
    return {
      module: 'bms/branchpurchase',
      form: {
        itemList: []
      },
      option: {
        labelWidth: 140,
        emptyBtn: false,
        submitBtn: false,
        column: [
          {
            label: '到店日期',
            prop: 'needDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '采购明细',
            prop: 'itemList',
            type: 'dynamic',
            span: 24,
            children: {
              addBtn: false,
              delBtn: false,
              align: 'center',
              headerAlign: 'center',
              column: [{
                label: '物料号',
                prop: 'materialFullCode',
                readonly: true
              }, {
                label: '品名',
                prop: 'materialName',
                readonly: true
              },
              {
                width: 337,
                label: '采购重量',
                prop: 'weight'
              }]
            },
            rules: [{
              required: true,
              message: '请输入'
            }]
          }
        ]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    this.loadShopData()
  },
  methods: {
    toBack () {
      this.$router.push({ path: '/bms/shoppurchase' })
    },
    loadShopData () {
      getLast(this.form.id).then(res => {
        this.form = res.data
      })
    },
    toSubmit () {
      if (!this.form.needDate) {
        this.$message({
          type: 'error',
          message: '请选择到店日期'
        })
        return false
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          submitLast(this.form).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.toBack()
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        }
      })
    }
  }
}
</script>
