<template>
  <basic-container>
    <avue-crud
            :row-style="rowStyle"
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/materialdetail',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        cellBtn: true,
        delBtn: false,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '品名',
            prop: 'name',
            search: true,
            editDisabled: 'ture'
          },
          {
            label: '物料号',
            prop: 'fullCode',
            search: true,
            editDisabled: 'ture'
          },
          {
            label: '图片',
            prop: 'logo',
            dataType: 'string',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            cell: true,
            width: 240,
            align: 'center'
          },
          {
            label: '单价/元',
            prop: 'price',
            type: 'number',
            cell: true,
            rules: [{
              required: true,
              message: '请输入单价',
              trigger: 'blur'
            }]
          },
          {
            label: '最佳使用期限',
            prop: 'optimumLife',
            cell: true,
            rules: [{
              required: true,
              message: '请输入最佳使用期限',
              trigger: 'blur'
            }]
          },
          {
            label: '存储条件',
            prop: 'storageRange',
            cell: true,
            rules: [{
              required: true,
              message: '请输入存储条件',
              trigger: 'blur'
            }]
          },
          {
            label: '原产地',
            prop: 'originPlace',
            cell: true,
            rules: [{
              required: true,
              message: '请输入原产地',
              trigger: 'blur'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            cell: true,
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_materialdetail_add,
        viewBtn: this.permission.vms_materialdetail_view,
        delBtn: this.permission.vms_materialdetail_delete,
        editBtn: this.permission.vms_materialdetail_edit
      }
    }
  },
  methods: {
    rowStyle ({ row }) {
      if (row.status === 2) {
        return {
          backgroundColor: '#E0DEDE'
        }
      }
    }
  }
}
</script>

