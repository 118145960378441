<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_aftersalereason_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/aftersalereason',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 4,
        searchSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '售后原因',
            prop: 'reason',
            width: 500,
            type: 'textarea',
            rules: [{
              required: true,
              message: '请输入售后原因'
            }]
          },
          {
            label: '原因类型',
            prop: 'serviceType',
            type: 'select',
            filterable: true,
            rules: [{
              required: true,
              message: '请输入原因类型'
            }],
            dicData: [
              {
                label: '取消订单',
                value: 'CANCEL'
              }, {
                label: '退货',
                value: 'RETURN_GOODS'
              }, {
                label: '换货',
                value: 'EXCHANGE_GOODS'
              }, {
                label: '退款',
                value: 'RETURN_MONEY'
              }, {
                label: '投诉',
                value: 'COMPLAIN'
              }
            ],
            search: true
          }
          // {
          //   label: '创建时间',
          //   prop: 'createTime',
          //   type: 'datetime',
          //   addDisabled: true,
          //   editDisabled: true
          // }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_aftersalereason_add,
        viewBtn: this.permission.shop_aftersalereason_view,
        delBtn: this.permission.shop_aftersalereason_delete,
        editBtn: this.permission.shop_aftersalereason_edit
      }
    }
  },
  methods: {
  }
}
</script>
