<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/article',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        viewBtn: false,
        selection: true,
        menuWidth: 150,
        searchMenuSpan: 5,
        column: [
          {
            label: '分类名称',
            prop: 'articleCategoryName',
            search: true,
            rules: [{
              required: true,
              message: '请输入分类名称'
            }]
          },
          {
            label: '文章标题',
            prop: 'title',
            rules: [{
              required: true,
              message: '请输入文章标题'
            }]
          },
          {
            label: '文章内容',
            prop: 'content',
            width: 500,
            type: 'textarea',
            rules: [{
              required: true,
              message: '请输入文章内容'
            }]
          },
          {
            label: '文章排序',
            prop: 'sort',
            width: 70,
            type: 'number',
            rules: [{
              required: true,
              message: '请输入文章排序'
            }]
          },
          {
            label: '文章类型',
            prop: 'type',
            editDisplay: false,
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入文章类型'
            }]
          },
          {
            label: '开放状态',
            prop: 'openStatus',
            type: 'radio',
            value: true,
            dicData: [{
              label: '开放',
              value: true
            }, {
              label: '关闭',
              value: false
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_article_add,
        viewBtn: this.permission.shop_article_view,
        delBtn: this.permission.shop_article_delete,
        editBtn: this.permission.shop_article_edit
      }
    }
  },
  methods: {
  }
}
</script>
