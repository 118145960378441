<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.bms_gift_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.bms_gift_edit"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            v-if="permission.bms_gift_delete"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="rowDel(row)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/gift',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectedGoods: '', // 已选商品列表，便于删除
      realSelectedGoods: [],
      sign: 'goods',
      selectionList: [],
      showGift: false,
      option: {
        tip: false,
        align: 'center',
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        selection: true,
        column: [
          {
            label: '获取门槛',
            prop: 'step',
            type: 'number',
            precision: 0,
            placeholder: '请设置赠品获取金额门槛',
            rules: [{
              required: true,
              message: '请输入赠品获取金额门槛'
            }]
          },
          {
            label: '全部/部分赠送',
            prop: 'singleMulti',
            type: 'select',
            dicData: [{ value: 0, label: '全部' }],
            rules: [{
              required: true,
              message: '请输入赠品名称'
            }]
          },
          {
            label: '赠品',
            prop: 'giftNames'
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_gift_add,
        viewBtn: this.permission.bms_gift_view,
        delBtn: this.permission.bms_gift_delete,
        editBtn: this.permission.bms_gift_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/bms/giftedit',
        query: { id: row.id }
      })
    },
    toAdd () {
      this.$router.push({ path: '/bms/giftedit' })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 60px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  .cover {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
    margin-right: 6px;
    img {
      width: 60px;
      height: 60px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    height: 100%;
    font-size: 14px;
    p {
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      &:nth-child(2n) {
        margin-top: 8px;
      }
    }
  }
  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: #409EFF;
    border-radius: 50%;
  }
  .active {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: url("../../assets/img/selected.png") no-repeat;
    background-position: right;
    background-size: 100%;
  }
}
</style>
