<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>商品发布</h2>
    </div>
    <avue-form :option="option" v-model="form" @submit="handleSubmit">
      <template slot="menuForm">
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getGoods } from '@/api/shop/goods'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/goods',
      form: {},
      option: {
        labelWidth: 120,
        column: [
          {
            label: '分类路径',
            prop: 'categoryPath'
          },
          {
            label: '物料号',
            prop: 'materialId'
          },
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: '卖点',
            prop: 'sellingPoint'
          },
          {
            label: '品牌ID',
            prop: 'brandId'
          },
          {
            label: '计量单位',
            prop: 'goodsUnit'
          },
          {
            label: '原图路径',
            prop: 'original'
          },
          {
            label: '评论数量',
            prop: 'commentNum'
          },
          {
            label: '成本价格',
            prop: 'cost'
          },
          {
            label: '商品好评率',
            prop: 'grade'
          },
          {
            label: '商品详情',
            prop: 'intro'
          },
          {
            label: '上架状态',
            prop: 'marketEnable'
          },
          {
            label: '商品移动端详情',
            prop: 'mobileIntro'
          },
          {
            label: '商品价格',
            prop: 'price'
          },
          {
            label: '库存',
            prop: 'quantity'
          },
          {
            label: '是否为推荐商品',
            prop: 'recommend'
          },
          {
            label: '销售模式',
            prop: 'salesModel'
          },
          {
            label: '是否自营',
            prop: 'selfOperated'
          },
          {
            label: '店铺ID',
            prop: 'storeId'
          },
          {
            label: '店铺名称',
            prop: 'storeName'
          },
          {
            label: '店铺分类',
            prop: 'shopCategoryPath'
          },
          {
            label: '小图路径',
            prop: 'small'
          },
          {
            label: '商品编号',
            prop: 'sn'
          },
          {
            label: '运费模板ID',
            prop: 'templateId'
          },
          {
            label: '缩略图路径',
            prop: 'thumbnail'
          },
          {
            label: '下架原因',
            prop: 'underMessage'
          },
          {
            label: '重量',
            prop: 'weight'
          },
          {
            label: '店铺分类路径',
            prop: 'storeCategoryPath'
          },
          {
            label: '大图路径',
            prop: 'big'
          },
          {
            label: '',
            prop: 'params'
          },
          {
            label: '商品类型',
            prop: 'goodsType'
          }
        ]
      }
    }
  },
  mounted () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getGoods(this.form.id).then(res => {
        this.form = res.data
      })
    }
  }
}
</script>
