<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.bms_branchconfig_add"
            type="primary"
            icon="el-icon-edit"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
        <el-button
            v-if="permission.bms_branchconfig_delete"
            icon="el-icon-delete"
            plain
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.bms_branchconfig_edit"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            icon="el-icon-switch-button"
            type="text"
            v-if="row.open === 0"
            @click="openOrClose(row)">
          已开店
        </el-button>
        <el-button
            icon="el-icon-switch-button"
            type="text"
            v-if="row.open === 1"
            @click="openOrClose(row)">
          已关店
        </el-button>
      </template>
    </avue-crud>
    <template slot="businessHours">
      <el-button
          type="text"
          icon="el-icon-info"
          size="small"
          @click.stop="getDetails('businessHours')">详情
      </el-button>
    </template>
    <template slot="delivery">
      <el-button
          type="text"
          icon="el-icon-info"
          size="small"
          @click.stop="getDetails('delivery')">详情
      </el-button>
    </template>
    <template slot="selfDelivery">
      <el-button
          type="text"
          icon="el-icon-info"
          size="small"
          @click.stop="getDetails('selfDelivery')">详情
      </el-button>
    </template>
    <el-dialog :visible.sync="businessHoursVisible">
      <el-table :data="form.businessHours" style="width: 100%">
        <el-table-column prop="start" label="开始时间" width="180" />
        <el-table-column prop="end" label="截止时间" width="180" />
        <el-table-column prop="refundTime" label="当天退款截止时间" v-if="refundTimeVisible">
        </el-table-column>
      </el-table>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList as getDetails } from '@/api/bms/branchconfigdetail'
import { submit } from '@/api/bms/branchconfig'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchconfig',
      form: {
        type: 0,
        businessHours: [],
        delivery: [],
        selfDelivery: []
      },
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      businessHoursVisible: false,
      deliveryVisible: false,
      selfDeliveryVisible: false,
      refundTimeVisible: false,
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        align: 'center',
        dialogFullscreen: true,
        column: [
          {
            label: '营业时间起',
            width: 100,
            prop: 'businessHoursStart',
            type: 'time',
            value: '09:00',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置营业起始时间'
          },
          {
            label: '营业时间止',
            width: 100,
            prop: 'businessHoursEnd',
            type: 'time',
            value: '22:00',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置营业结束时间'
          },
          {
            label: '全天营业',
            prop: 'isAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天营业'
          },
          {
            label: '营业时间配置',
            prop: 'businessHours',
            width: 100,
            slot: true
          },
          {
            label: '允许退款',
            prop: 'isRefunded',
            type: 'switch',
            value: 0,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天营业'
          },
          {
            label: '配送',
            prop: 'isDelivered',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不提供' }, { value: 1, label: '提供' }],
            placeholder: '请设置门店是否配送'
          },
          {
            label: '全天配送',
            prop: 'isDeliveredAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天配送'
          },
          {
            label: '配送时间配置',
            prop: 'delivery',
            width: 100,
            slot: true
          },
          {
            label: '允许自提',
            prop: 'isSelfDelivered',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不允许' }, { value: 1, label: '允许' }],
            placeholder: '请设置门店是否允许自提'
          },
          {
            label: '全天自提',
            prop: 'isSelfDeliveredAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不允许' }, { value: 1, label: '允许' }],
            placeholder: '请设置门店是否全天允许自提'
          },
          {
            label: '自提时间配置',
            prop: 'selfDelivery',
            width: 100,
            slot: true
          },
          {
            label: '状态',
            prop: 'open',
            type: 'select',
            filterable: true,
            dicData: [{
              value: 0,
              label: '开店中'
            }, {
              value: 1,
              label: '关店中'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchconfig_add,
        viewBtn: this.permission.bms_branchconfig_view,
        delBtn: this.permission.bms_branchconfig_delete,
        editBtn: this.permission.bms_branchconfig_edit
      }
    },
    businessHoursData () {
      return this.form.businessHours || []
    },
    deliveryData () {
      return this.form.delivery || []
    },
    selfDeliveryData () {
      return this.form.selfDelivery || []
    }
  },
  methods: {
    getDetails (type) {
      getDetails(null, null, { ...type }).then(res => {
        if (res.success) {
          this.details = res.data
          if (type === 1) {
            this.refundTimeVisible = true
          } else {
            this.refundTimeVisible = false
          }
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    toAdd () {
      this.$router.push({ path: '/bms/branchconfigedit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/bms/branchconfigedit',
        query: { id: row.id }
      })
    },
    openOrClose (row) {
      this.form = row
      if (this.form.open === 0) {
        this.$confirm('确认关闭吗？', '提示', {}).then(() => {
          this.form.open = 1
          submit(this.form).then(() => {
            this.$message({
              type: 'success',
              message: '关闭成功!'
            })
          })
        })
      } else if (this.form.open === 1) {
        this.$confirm('确认开启吗？', '提示', {}).then(() => {
          this.form.open = 0
          submit(this.form).then(() => {
            this.$message({
              type: 'success',
              message: '开启成功!'
            })
          })
        })
      } else {
        this.$message({ message: '操作失败，请重试', type: 'error' })
      }
    }
  }
}
</script>
