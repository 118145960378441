import { render, staticRenderFns } from "./giftedit.vue?vue&type=template&id=56d3a9ce&scoped=true&"
import script from "./giftedit.vue?vue&type=script&lang=js&"
export * from "./giftedit.vue?vue&type=script&lang=js&"
import style0 from "./giftedit.vue?vue&type=style&index=0&id=56d3a9ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d3a9ce",
  null
  
)

export default component.exports