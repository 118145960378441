<template>
  <basic-container>
    <avue-crud
            :option="option"
            :defaults.sync="defaults"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { skuInfo } from '@/api/shop/coupon'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/fulldiscount',
      defaults: {},
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        menuWidth: 140,
        dialogWidth: '90%',
        border: true,
        index: true,
        editBtn: false,
        delBtn: false,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '活动名称',
            prop: 'promotionName',
            span: 24,
            rules: [{
              required: true,
              message: '请输入活动名称'
            }]
          },
          {
            label: '活动开始时间',
            prop: 'startTime',
            type: 'datetime',
            width: 100,
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入活动开始时间'
            }]
          },
          {
            label: '活动结束时间',
            prop: 'endTime',
            type: 'datetime',
            width: 100,
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入活动结束时间'
            }]
          },
          {
            label: '活动标题',
            prop: 'title',
            rules: [{
              required: true,
              message: '请输入活动标题'
            }]
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '活动说明',
            prop: 'description',
            rules: [{
              required: true,
              message: '请输入活动说明'
            }]
          },
          {
            label: '优惠门槛金额',
            prop: 'fullMoney',
            width: 100,
            rules: [{
              required: true,
              message: '请输入优惠门槛金额'
            }]
          },
          {
            label: '减现金或折扣',
            prop: 'fullType',
            type: 'switch',
            hide: true,
            value: 'fullMinus',
            dicData: [{
              label: '减现金',
              value: 'fullMinus'
            }, {
              label: '折扣',
              value: 'fullRate'
            }],
            rules: [{
              required: true,
              message: '请输入减现金或折扣'
            }]
          },
          {
            label: '减现金',
            prop: 'fullMinus',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入减现金'
            }]
          },
          {
            label: '打折',
            prop: 'fullRate',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入打折'
            }]
          },
          {
            label: '是否赠优惠券',
            prop: 'couponFlag',
            type: 'radio',
            width: 100,
            span: 24,
            value: false,
            dicData: [{
              label: '是',
              value: true
            }, {
              label: '否',
              value: false
            }],
            rules: [{
              required: true,
              message: '请输入是否赠优惠券'
            }]
          },
          {
            label: '是否包邮',
            prop: 'freeFreightFlag',
            type: 'radio',
            span: 24,
            value: false,
            dicData: [{
              label: '是',
              value: true
            }, {
              label: '否',
              value: false
            }],
            rules: [{
              required: true,
              message: '请输入是否包邮'
            }]
          },
          {
            label: '是否有赠品',
            prop: 'giftFlag',
            type: 'radio',
            span: 24,
            width: 100,
            value: false,
            dicData: [{
              label: '是',
              value: true
            }, {
              label: '否',
              value: false
            }],
            rules: [{
              required: true,
              message: '请输入是否有赠品'
            }]
          },
          {
            label: '是否赠送积分',
            prop: 'pointFlag',
            type: 'radio',
            width: 100,
            span: 24,
            value: false,
            dicData: [{
              label: '是',
              value: true
            }, {
              label: '否',
              value: false
            }],
            rules: [{
              required: true,
              message: '请输入是否赠送积分'
            }]
          },
          {
            label: '赠送积分',
            prop: 'point',
            type: 'number',
            addDisplay: false,
            min: 0,
            max: 99999,
            rules: [{
              required: true,
              message: '请输入赠送多少积分'
            }]
          },
          {
            label: '品类描述',
            prop: 'scopeType',
            type: 'radio',
            value: 'ALL',
            span: 24,
            dicUrl: '/sys/dict/code/coupon_scope_type',
            rules: [{
              required: true,
              message: '请输入品类描述'
            }]
          },
          {
            label: '关联商品',
            prop: 'promotionGoodsList',
            type: 'dynamic',
            hide: true,
            addDisplay: false,
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              column: [
                {
                  label: '商品名称',
                  prop: 'skuId',
                  addDisplay: false,
                  type: 'select',
                  filterable: true,
                  dicUrl: '/shop/goods/goodsList',
                  change: ({ value }) => {
                    skuInfo(value).then(res => {
                      this.form.promotionGoodsList.forEach(item => {
                        if (item.skuId === res.data[0].skuId) {
                          item.quantity = res.data[0].quantity
                          item.originalPrice = res.data[0].originalPrice
                          item.goodsName = res.data[0].goodsName
                          item.goodsId = res.data[0].goodsId
                          item.price = res.data[0].originalPrice
                        }
                      })
                    })
                  },
                  rules: [{
                    required: true,
                    message: '请输入关联商品'
                  }]
                },
                {
                  label: '单价',
                  prop: 'originalPrice',
                  readonly: true
                },
                {
                  label: '库存',
                  prop: 'quantity',
                  readonly: true
                },
                {
                  label: '商品名称',
                  prop: 'goodsName',
                  hide: true
                },
                {
                  label: '商品id',
                  prop: 'goodsId',
                  hide: true
                },
                {
                  label: '价格',
                  prop: 'price',
                  hide: true
                }
              ]
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_fulldiscount_add,
        viewBtn: this.permission.shop_fulldiscount_view,
        delBtn: this.permission.shop_fulldiscount_delete,
        editBtn: this.permission.shop_fulldiscount_edit
      }
    }
  },
  watch: {
    'form.fullType' (val) {
      if (val === 'fullRate') {
        this.defaults.fullMinus.addDisplay = false
        this.defaults.fullMinus.viewDisplay = false
        this.defaults.fullRate.addDisplay = true
        this.defaults.fullRate.viewDisplay = true
      } else {
        this.defaults.fullMinus.addDisplay = true
        this.defaults.fullMinus.viewDisplay = true
        this.defaults.fullRate.addDisplay = false
        this.defaults.fullRate.viewDisplay = false
      }
    },
    'form.pointFlag' (val) {
      if (val === true) {
        this.defaults.point.addDisplay = true
        this.defaults.point.viewDisplay = true
        this.defaults.pointFlag.span = 12
      } else {
        this.defaults.point.addDisplay = false
        this.defaults.point.viewDisplay = false
        this.defaults.pointFlag.span = 24
      }
    },
    'form.scopeType' (val) {
      if (val === 'PORTION_GOODS') {
        this.defaults.promotionGoodsList.addDisplay = true
        this.defaults.promotionGoodsList.viewDisplay = true
      } else {
        this.defaults.promotionGoodsList.addDisplay = false
        this.defaults.promotionGoodsList.viewDisplay = false
      }
    }
  },
  methods: {
  }
}
</script>
