<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="warning"
            size="small"
            icon="el-icon-bottom"
            @click.prevent="exportCattleTemplate"
        >导入模板下载
        </el-button>
        <el-upload
            class="filter-item"
            :show-file-list="false"
            size="small"
            :action="handleImport()"
            :headers="uploadHeaders()"
            :on-success="uploadSuc"
            style="display: inline-block">
          <el-button type="info" icon="el-icon-bottom">导 入</el-button>
        </el-upload>
        <el-button
            type="success"
            size="small"
            icon="el-icon-top"
            @click.prevent="exportCattle"
        >导 出
        </el-button>
      </template>
      <template slot="menu" slot-scope="op">
        <el-button type="text" icon="el-icon-check" size="small" @click.prevent="tobeef(op)">关联大块肉</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/cattle',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        align: 'center',
        index: true,
        indexLabel: '序号',
        selection: true,
        labelWidth: 200,
        searchMenuSpan: 4,
        menuWidth: 350,
        searchSpan: 5,
        column: [
          {
            label: '所属供应商',
            prop: 'vendorId',
            width: 180,
            type: 'select',
            filterable: true,
            dicUrl: '/vms/vendor/dict',
            rules: [{
              message: '请输入所属供应商',
              required: true
            }]
          },
          {
            label: '品种',
            prop: 'breed',
            search: true,
            rules: [{
              message: '请输入品种',
              required: true
            }]
          },
          {
            label: '产地',
            prop: 'origin',
            search: true,
            span: 24,
            maxlength: 10,
            showWordLimit: true,
            rules: [{
              message: '请输入产地',
              required: true
            }]
          },
          {
            label: '耳标',
            prop: 'earTag',
            width: 100,
            search: true,
            rules: [{
              message: '请输入耳标',
              required: true
            }]
          },
          {
            label: '月龄',
            prop: 'moonAge',
            search: true,
            type: 'number',
            rules: [{
              message: '请输入月龄',
              required: true
            }]
          },
          {
            label: '饲料',
            prop: 'fodder',
            hide: true,
            rules: [{
              message: '请输入饲料',
              required: true
            }]
          },
          {
            label: '出栏日期',
            prop: 'barnTime',
            width: 100,
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              message: '请输入出栏日期',
              required: true
            }]
          },
          {
            label: '产地检疫证号',
            prop: 'originNo',
            width: 100,
            rules: [{
              required: true,
              message: '请输入产地检疫证号'
            }]
          },
          {
            label: '活牛订单编号',
            prop: 'orderSn',
            width: 100
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.option.addBtn = this.permissionList.addBtn
    this.option.viewBtn = this.permissionList.viewBtn
    this.option.delBtn = this.permissionList.delBtn
    this.option.editBtn = this.permissionList.editBtn
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_cattle_add,
        viewBtn: this.permission.vms_cattle_view,
        delBtn: this.permission.vms_cattle_delete,
        editBtn: this.permission.vms_cattle_edit
      }
    }
  },
  methods: {
    tobeef (e) {
      this.$router.push({
        path: '/vms/beef',
        query: {
          vendorNum: e.row.vendorId,
          cattleNum: e.row.id
        }
      })
    },
    exportCattle () {
      this.handleExport('活牛信息导出')
    },
    exportCattleTemplate () {
      this.handleTemplateExport('活牛信息模板下载')
    }
  }
}
</script>
