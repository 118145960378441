<!--活牛实际屠宰-->
<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        v-model="form"
        @search-change="searchChangeAct"
        @search-reset="searchResetAct"
        @row-update="rowUpdateAct"
        @on-load="onLoadCattleActPage">
      <template slot="menuLeft">
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toView(row)">查看
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { cattleAct, submitBom } from '@/api/vms/materialbom'

export default {
  mixins: [mixin],
  data () {
    return {
      MaterialBomDTO: {
        needDate: null
      },
      module: 'vms/materialbom',
      form: {},
      search: {},
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        indexLabel: '序号',
        searchMenuSpan: 5,
        column: [
          {
            label: '到店日期',
            prop: 'needDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            search: true,
            editDisplay: false
          },
          {
            label: '参考屠宰数量(头)',
            prop: 'cattleNumMax',
            editDisplay: false
          },
          {
            label: '实际屠宰数量(头)',
            prop: 'cattleConfirmNum'
          },
          {
            label: '备注',
            prop: 'remark'
          },
          {
            label: 'type',
            prop: 'type',
            hide: true,
            editDisplay: false
          }
        ]
      },
      data: [],
      params: {}
    }
  },
  methods: {
    onLoadCattleActPage (page) {
      cattleAct(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    toView (row) {
      this.$router.push({
        path: '/vms/bomact',
        query: { needDate: row.needDate }
      })
    },
    rowUpdateAct (row, index, done, loading) {
      this.handleSubmitAct(row, done, loading)
    },
    handleSubmitAct: function (row, done, loading) {
      row.type = 1
      submitBom(row).then((res) => {
        done()
        if (res.success) {
          this.onLoadCattleActPage(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    searchChangeAct (params, done) {
      this.search = params
      this.onLoadCattleActPage(this.page)
      done()
    },
    searchResetAct () {
      this.search = {}
      this.onLoadCattleActPage(this.page)
    }
  }
}
</script>
