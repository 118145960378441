<template>
  <basic-container>
    <div class="end" style="margin-bottom: 15px">
      <el-button
          type="success"
          size="small"
          @click.stop="toSubmit()">提交
      </el-button>
      <el-button
          type="primary"
          size="small"
          @click.stop="toBack()">返回
      </el-button>
    </div>
    <avue-form ref="form" :option="option" v-model="form" :defaults.sync="defaults">
    </avue-form>
  </basic-container>
</template>

<script>
import { submitGiftCardList } from '@/api/shop/giftcard'

export default {
  data () {
    return {
      module: 'bms/branchpurchase',
      defaults: {},
      form: {
        skuList: []
      },
      option: {
        labelWidth: 140,
        emptyBtn: false,
        submitBtn: false,
        column: [
          {
            label: '类型',
            prop: 'type',
            value: 1,
            type: 'radio',
            span: 24,
            dicData: [{
              label: '现金卡',
              value: 1
            }, {
              label: '实物卡',
              value: 2
            }],
            rules: [{
              required: true,
              message: '请输入类型：1现金卡 2实物卡'
            }]
          },
          {
            label: '余额',
            prop: 'money',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入余额'
            }]
          },
          {
            label: '有效天数',
            prop: 'expirationDays',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入有效天数'
            }]
          },
          {
            label: '数量',
            prop: 'count',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入数量'
            }]
          },
          {
            label: '使用规则',
            prop: 'useRule'
          },
          {
            label: '选择商品',
            prop: 'skuList',
            type: 'dynamic',
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              column: [{
                label: '关联商品',
                prop: 'skuName'
              }, {
                label: '数量',
                prop: 'skuAmount'
              },
              {
                label: '单价',
                prop: 'skuPrice'
              }]
            },
            rules: [{
              required: true,
              message: '请输入'
            }]
          }
        ]
      }
    }
  },
  watch: {
    'form.type' (val) {
      if (val === 1) {
        this.defaults.skuList.display = false
        this.defaults.money.display = true
      } else {
        this.defaults.skuList.display = true
        this.defaults.money.display = false
      }
    }
  },
  methods: {
    toBack () {
      this.$router.push({ path: '/shop/giftcard' })
    },
    toSubmit () {
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          submitGiftCardList(this.form).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '操作成功!'
              })
              this.toBack()
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        }
      })
    }
  }
}
</script>
