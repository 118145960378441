import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/vms/materialbom/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/vms/materialbom/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/vms/materialbom/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/vms/materialbom/submit',
    method: 'post',
    data: row
  })
}

export const page = (MaterialBomDTO) => {
  return request({
    url: '/vms/materialbom/bom',
    method: 'get',
    params: MaterialBomDTO
  })
}

export const bom = (MaterialBomDTO) => {
  return request({
    url: '/vms/materialbom/page',
    method: 'get',
    params: MaterialBomDTO
  })
}

export const actPage = (MaterialBomDTO) => {
  return request({
    url: '/vms/materialbom/actBom',
    method: 'get',
    params: MaterialBomDTO
  })
}

export const cattlePre = (current, size, params) => {
  return request({
    url: '/vms/materialbom/cattlePrePage',
    method: 'get',
    params:
        {
          ...params,
          current,
          size
        }
  })
}

export const cattleAct = (current, size, params) => {
  return request({
    url: '/vms/materialbom/cattleActPage',
    method: 'get',
    params:
        {
          ...params,
          current,
          size
        }
  })
}

export const submitBom = (row) => {
  return request({
    url: '/vms/materialbom/submitAct',
    method: 'post',
    data: row
  })
}
