<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @row-click="handleRowClick"
        @on-load="onLoad">
      <template slot="search">
        <el-form :inline="true">
          <el-form-item label="物料:">
            <good-select multiple v-model="search.materialIds" url="/vms/beef/material"></good-select>
          </el-form-item>
          <el-form-item label="活动时间范围:">
            <el-date-picker
                v-model="search.dateRange"
                type="daterange"
                value-format="yyyy-MM-dd"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.bms_branchactivity_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
        title="选择物料"
        top="5vh"
        :append-to-body="false"
        :visible.sync="showGift"
        width="80%">
      <div class="el-dialog-div">
        <div class="selectAll" style="margin-bottom: 10px;">
          <div :class="'checkbox ' + (isSelectedAll?'active':'inactive')" style="margin-right: 10px;" @click="switchSelectAll"/>
          <div>全选</div>
        </div>
        <el-row :gutter="20">
          <el-checkbox-group>
            <el-col :span="8" v-for="(item, index) in materials" :key="index">
              <div class="item">
                <div :id="item.id" :class="'checkbox ' + (item.isActive?'active':'inactive')" style="margin-right: 10px;" @click="materialChanged"></div>
                <div class="cover">
                  <img :src="item.logo?item.logo:materialImg"/>
                </div>
                <div class="info">
                  <p>
                    {{ item.name }}
                  </p>
                  <p>{{ item.price }}</p>
                </div>
              </div>
            </el-col>
          </el-checkbox-group>
        </el-row>
        <div>
          <div class="block">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[20, 40, 60, 100]"
                :page-size.sync="materialPage.pageSize"
                :current-page.sync="materialPage.currentPage"
                layout="sizes, prev, pager, next"
                :total="materialPage.total">
            </el-pagination>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="showGift = false">取 消</el-button>
            <el-button type="primary" @click="selectMaterials">确 定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import GoodSelect from '@/components/good-select'
import { getListInActivity } from '@/api/vms/material'
import materialImg from '../../assets/img/material.png'
import { getList, remove, submit } from '@/api/common'

export default {
  mixins: [mixin],
  components: { GoodSelect },
  data () {
    return {
      module: 'bms/branchactivity',
      form: {},
      materialImg: materialImg,
      search: {
        type: 1,
        materialId: undefined,
        dateRange: []
      },
      materialSearch: {
        type: 1,
        level: 4
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      materialPage: {
        pageSize: 20,
        currentPage: 1,
        total: 0
      },
      isSelectedAll: false,
      selectionList: [],
      materialsInActivity: [],
      value: undefined,
      option: {
        tip: false,
        align: 'center',
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        cellBtn: true,
        addRowBtn: true,
        selection: true,
        rowKey: 'value',
        column: [
          {
            label: '内购价',
            prop: 'value',
            type: 'number',
            min: 0,
            step: 1,
            precision: 2,
            controlsPosition: '',
            cell: true,
            placeholder: '请设置内购价',
            rules: [{
              required: true,
              message: '请设置内购价'
            }],
            change: ({ row, value }) => {
              this.activity = row
              this.$refs.crud.data.forEach((item, index) => {
                if (index !== row.$index && row.value === item.value) {
                  this.$message.error('已有相同内购活动，请编辑原活动！')
                }
              })
            }
          },
          {
            label: '内购开始时间',
            prop: 'startDate',
            type: 'datetime',
            cell: true,
            placeholder: '请设置内购开始时间',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请设置内购开始时间'
            }]
          },
          {
            label: '内购结束时间',
            prop: 'expireDate',
            type: 'datetime',
            cell: true,
            placeholder: '请设置内购结束时间',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请设置内购结束时间'
            }]
          },
          {
            label: '物料',
            prop: 'materialIds',
            cell: true,
            type: 'select',
            multiple: true,
            popperAppendToBody: false,
            dicUrl: '/vms/beef/material',
            rules: [{
              required: true,
              message: '请选择物料'
            }],
            focus: (e) => {
              this.showGift = true
              this.listMaterials()
            },
            change: ({ row, value }) => {
              this.activity = row
              this.$refs.crud.data.forEach((item, index) => {
                if (index === row.$index) {
                  item.$cellEdit = true
                } else {
                  item.$cellEdit = false
                }
              })
            }
          }
        ]
      },
      data: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date()
              const end = new Date()
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '昨日',
            onClick (picker) {
              const yesterday = new Date(new Date() - 1000 * 60 * 60 * 24)
              picker.$emit('pick', [yesterday, yesterday])
            }
          }, {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6)
              picker.$emit('pick', [start, end])
            }
          }, {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          }]
      },
      showGift: false,
      materials: [],
      activity: {}
    }
  },
  watch: {
    showGift: {
      handler (val) {
        if (val === false) {
          this.materialPage = {
            pageSize: 20,
            currentPage: 1,
            total: 0
          }
        }
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchactivity_add,
        viewBtn: this.permission.bms_branchactivity_view,
        delBtn: this.permission.bms_branchactivity_delete,
        editBtn: this.permission.bms_branchactivity_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      if (this.search.dateRange && this.search.dateRange.length === 2) {
        this.search.start = this.search.dateRange[0]
        this.search.end = this.search.dateRange[1]
      } else {
        this.search.start = null
        this.search.end = null
      }
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    listMaterials () {
      this.materialSearch.value = this.activity.value
      this.materialSearch.start = this.activity.startDate
      this.materialSearch.end = this.activity.expireDate
      if (!this.materialSearch.start || !this.materialSearch.end) {
        this.$message.error('请同时指定有效开始及截止日期')
        return
      }
      const data = this.data.filter(item => item.$cellEdit)[0]
      if (data.materialIds && data.materialIds.length) {
        const materialIds = data.materialIds.split(',')
        this.materialsInActivity = this.materialsInActivity.concat(materialIds.filter(item => this.materialsInActivity.indexOf(item) < 0))
      }
      getListInActivity(this.materialPage.currentPage, this.materialPage.pageSize, this.materialSearch).then(res => {
        this.materialSearch.value = null
        if (res.success && res.data.records && res.data.records.length) {
          this.materials = res.data.records
          if (this.materialsInActivity && this.materialsInActivity.length) {
            this.materials.forEach(item => {
              if (this.materialsInActivity.indexOf(item.id) >= 0) {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
          } else {
            this.materialsInActivity = this.materialsInActivity.concat(this.materials.filter(item => item.isActive && this.materialsInActivity.indexOf(item.id) < 0).map(item => item.id))
          }
          this.materialPage.total = res.data.total
        } else {
          this.$message.error(res.msg ? res.msg : '获取物料列表失败！')
        }
      })
    },
    handleRowClick (row, event, column) {
      this.$refs.crud.data.forEach((item, index) => {
        if (index === row.$index) {
          item.$cellEdit = true
        } else {
          item.$cellEdit = false
        }
      })
      this.currentIndex = row.$index
      if (event) {
        this.columnKey = event.columnKey
      }
    },
    // 全选
    switchSelectAll () {
      this.isSelectedAll = !this.isSelectedAll
      this.materials.forEach(item => {
        item.isActive = this.isSelectedAll
        return item
      })
      if (this.isSelectedAll) {
        this.materialsInActivity = this.materialsInActivity.concat(this.materials.filter(item => this.materialsInActivity.indexOf(item.id) < 0).map(item => item.id))
      }
    },
    // 物料窗口点确定
    selectMaterials: function () {
      const data = this.data.filter(item => item.$cellEdit)[0]
      if (this.materialsInActivity.length) {
        this.showGift = false
        this.materials.forEach(item => {
          if (this.materialsInActivity.indexOf(item.id) >= 0) {
            item.isActive = true
          } else {
            item.isActive = false
          }
        })
        data.materialIds = this.materialsInActivity.join(',')
        console.log('after', data.materialIds)
      } else {
        if (!data.materialIds || !data.materialIds.length) {
          this.$message.error('请至少选择一个物料！')
        } else {
          this.showGift = false
          const materialIds = data.materialIds.split(',')
          materialIds.forEach(item => {
            this.materialsInActivity.push({ id: item })
          })
        }
      }
    },
    // 复选框单击，设置物料isActive，且活动物料数组中插入或移出
    materialChanged (e) {
      const className = e.target.className.split(' ')
      const isActive = className[1] === 'active'
      e.target.className = className[0] + ' ' + (isActive ? 'inactive' : 'active')
      const id = e.target.id
      this.materials.forEach(item => {
        if (item.id === id) {
          item.isActive = !isActive
          if (item.isActive && this.materialsInActivity.indexOf(id) < 0) {
            this.materialsInActivity.push(id)
          } else {
            this.materialsInActivity.splice(this.materialsInActivity.indexOf(id), 1)
          }
        }
      })
    },
    handleSizeChange (val) {
      this.materialPage.pageSize = val
      this.listMaterials()
    },
    handleCurrentChange (val) {
      this.materialPage.currentPage = val
      this.listMaterials()
    },
    handleSubmit: function (row, done, loading) {
      if (row.expireDate < row.startDate) {
        this.$message.error('活动结束日期不允许早于开始日期')
        this.onLoad(this.page, this.search)
        return
      }
      row.type = 1
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        this.onLoad(this.page, this.search)
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return remove(this.module, row.value + '-1')
      }).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__wrapper {
}
.el-dialog-div {
  height: 70vh;
  overflow-x: hidden;
}
.block {
  margin: 16px 0;
  text-align: right;
}
.dialog-footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 16px;
}
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;

  .cover {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
    margin-right: 6px;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    height: 100%;
    font-size: 14px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &:nth-child(2n) {
        margin-top: 8px;
      }
    }
  }

  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: #409EFF;
    border-radius: 50%;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 14px;
  height: 14px;
}
.active {
  background: url("../../assets/img/selected.png") no-repeat right;
  background-size: 100%;
}
.inactive {
  border-radius: 50%;
  border: 1px solid #7b7b7b;
  background: #fafafa;
}
</style>
