<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportBranchOrder()">导出
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toView(row)">查看详情
        </el-button>
      </template>
      <template slot="dateRangeSearch">
        <el-date-picker
            v-model="search.dateRange"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
        </el-date-picker>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/common'
import { formatDate } from '@/util/date'
import FileSaver from 'file-saver'
import { exportExcelBranchOrder } from '@/api/bms/branchorder'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchorder',
      form: {},
      search: {
        dateRange: []
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        align: 'center',
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '所属门店',
            prop: 'branchId',
            width: 100,
            type: 'select',
            filterable: true,
            search: true,
            placeholder: '请选择门店',
            searchSpan: 8,
            dicUrl: 'bms/branch/findbranch'
          },
          {
            label: '订单编号',
            prop: 'orderSn',
            width: 150,
            placeholder: '请输入订单编号',
            searchSpan: 8,
            search: true
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '实际支付',
            prop: 'payAmount'
          },
          {
            label: '支付方式',
            prop: 'payType',
            type: 'select',
            filterable: true,
            dataType: 'string',
            dicUrl: '/sys/dict/code/pay_type'
          },
          {
            label: '订单状态',
            prop: 'status',
            type: 'select',
            placeholder: '请选择订单状态',
            searchSpan: 8,
            filterable: true,
            search: true,
            dicData: [{
              label: '待付款',
              value: 0
            }, {
              label: '已支付',
              value: 2
            }, {
              label: '已关闭',
              value: 3
            }, {
              label: '无效订单',
              value: 4
            }]
          },
          {
            label: '支付时间',
            prop: 'paymentTime',
            width: 150,
            addDisabled: true,
            editDisabled: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            hide: true,
            addDisabled: true,
            editDisabled: true
          },
          {
            label: '抹零单位',
            prop: 'eraseUnit',
            cell: true,
            hide: true,
            type: 'select',
            dicData: [{ value: 0, label: '不抹零' }, { value: 1, label: '元' }, { value: 2, label: '角' }, { value: 3, label: '分' }],
            rules: [{
              required: true,
              message: '请设置抹零单位'
            }]
          },
          {
            label: '下单时间',
            prop: 'dateRange',
            searchSlot: true,
            search: true,
            searchSpan: 8,
            hide: true
          },
          {
            label: '活动名称',
            prop: 'activityType'
          }
        ]
      },
      data: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchorder_add,
        viewBtn: this.permission.bms_branchorder_view,
        delBtn: this.permission.bms_branchorder_delete,
        editBtn: this.permission.bms_branchorder_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      if (this.search.dateRange && this.search.dateRange.length !== 2) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        this.search.dateRange = [start, end]
      }
      if (this.search.dateRange && this.search.dateRange[0]) {
        this.search.start = formatDate(this.search.dateRange[0], 'yyyy-MM-dd')
      }
      if (this.search.dateRange && this.search.dateRange[1]) {
        this.search.end = formatDate(this.search.dateRange[1], 'yyyy-MM-dd')
      }
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    exportBranchOrder () {
      if (this.search.dateRange && this.search.dateRange.length !== 2) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        this.search.dateRange = [start, end]
      }
      if (this.search.dateRange && this.search.dateRange[0]) {
        this.search.start = formatDate(this.search.dateRange[0], 'yyyy-MM-dd')
      }
      if (this.search.dateRange && this.search.dateRange[1]) {
        this.search.end = formatDate(this.search.dateRange[1], 'yyyy-MM-dd')
      }
      if (this.selectionList.length > 0) {
        this.search.snList = this.selectionList.map(item => item.orderSn).join(',')
      } else {
        this.search.snList = null
      }
      this.handleExportBranchOrder('订单信息导出')
    },
    handleExportBranchOrder (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportExcelBranchOrder(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    toView (row) {
      this.$router.push({
        path: '/bms/branchorderview',
        query: { id: row.id }
      })
    }
  }
}
</script>
