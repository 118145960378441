<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChangeShop"
        @search-reset="searchResetShop"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoadShopPage">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getShopListPage } from '@/api/bms/branchpurchase'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchpurchase',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '单号',
            prop: 'sn',
            search: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            search: true
          }, {
            label: '到店时间',
            prop: 'needDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            search: true
          }
        ]
      },
      data: []
    }
  },
  methods: {
    toAdd () {
      this.$router.push({ path: '/bms/shoppurchaseedit' })
    },
    toEdit (row) {
      this.$router.push({
        path: '/bms/shoppurchaseedit',
        query: { id: row.id }
      })
    },
    searchChangeShop (params, done) {
      this.search = params
      this.onLoadShopPage(this.page)
      done()
    },
    searchResetShop () {
      this.search = {}
      this.onLoadShopPage(this.page)
    },
    async onLoadShopPage (page) {
      getShopListPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
