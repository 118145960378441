<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>门店信息</h2>
    </div>
    <avue-form :option="option" v-model="form" @submit="handleSubmit">
      <template slot="menuForm">
        <el-button @click="toBranch">返回</el-button>
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import { submit, getDetail } from '@/api/bms/branch'

export default {
  data () {
    return {
      module: 'bms/branch',
      form: {},
      search: {},
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        labelWidth: 200,
        column: [
          {
            label: '店铺名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '地址',
            prop: 'addressDetail',
            rules: [{
              required: true,
              message: '请输入地址'
            }]
          },
          {
            label: '经纬度',
            prop: 'map',
            type: 'map',
            rules: [{
              required: true,
              message: '请输入经纬度'
            }]
          },
          {
            label: '联系方式',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入联系方式'
            }]
          },
          {
            label: '配送范围  /m',
            prop: 'scope',
            type: 'number',
            value: 5000,
            min: 500,
            step: 100,
            precision: 0,
            placeholder: '请设置配送范围',
            rules: [{
              required: true,
              message: '请输入配送范围'
            }]
          },
          {
            label: '面积  /m²',
            prop: 'area',
            rules: [{
              required: true,
              message: '请输入店铺面积'
            }]
          },
          {
            label: '租金  元/月',
            prop: 'rent',
            rules: [{
              required: true,
              message: '请输入店铺租金'
            }]
          },
          {
            label: '租期  /月',
            prop: 'tenancyTerm',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入店铺租期'
            }]
          },
          {
            label: '涨幅情况',
            prop: 'rentDes',
            rules: [{
              required: true,
              message: '请输入店铺涨幅情况'
            }]
          },
          {
            label: '签约日期',
            prop: 'signDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: '请输入店铺签约日期'
            }]
          },
          {
            label: '到期日期',
            prop: 'dueDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: '请输入店铺到期日期'
            }]
          },
          {
            label: '营业执照',
            prop: 'businessLicense',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            span: 24,
            rules: [{
              required: true,
              message: '请输入店铺营业执照'
            }]
          },
          {
            label: '食品经营许可证',
            prop: 'foodBusinessLicense',
            type: 'upload',
            listType: 'picture-img',
            span: 24,
            oss: 'ali',
            rules: [{
              required: true,
              message: '请输入店铺食品经营许可证'
            }]
          },
          {
            label: '卫生登记证',
            prop: 'healthLicense',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            rules: [{
              required: true,
              message: '请输入店铺卫生登记证'
            }]
          },
          {
            label: '其他证件照',
            prop: 'otherLicense',
            type: 'upload',
            listType: 'picture-card',
            oss: 'ali',
            dataType: 'string',
            span: 24,
            rules: [{
              required: true,
              message: '请输入店铺其他证件照'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    handleSubmit () {
      const map = this.form.map
      if (map) {
        this.form.center = map[1] + ' ' + map[0]
        this.form.address = map[2]
      }
      submit(this.form).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.toBranch()
      })
    },
    toBranch () {
      this.$router.push({ path: '/bms/branch' })
    }
  }
}
</script>

<style lang="scss">
.el-empty{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
