<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="getListPage">
      <template slot="menuLeft">
        <el-button
                type="primary"
                size="small"
                icon="el-icon-back"
                plain
                @click="toBranchPurchase">返回
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getItem } from '@/api/bms/branchpurchaseitem'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchpurchaseitem',
      form: {},
      search: {},
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: false,
        viewBtn: false,
        delBtn: false,
        menu: false,
        addBtn: false,
        selection: false,
        searchMenuSpan: 5,
        column: [
          {
            label: '所属采购单',
            prop: 'purchaseId',
            type: 'select',
            filterable: true,
            dicUrl: '/bms/branchpurchase/dict'
          },
          {
            label: '物料号',
            prop: 'materialFullCode'
          },
          {
            label: '品名',
            prop: 'materialName'
          },
          {
            label: '采购重量 (公斤)',
            type: 'number',
            prop: 'weight'
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.form.purchaseId = this.$route.query.purchaseId
    if (this.form.purchaseId) {
      this.getListPage()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchpurchaseitem_add,
        viewBtn: this.permission.bms_branchpurchaseitem_view,
        delBtn: this.permission.bms_branchpurchaseitem_delete,
        editBtn: this.permission.bms_branchpurchaseitem_edit
      }
    }
  },
  methods: {
    toBranchPurchase () {
      this.$router.push({ path: '/bms/branchprepurchase' })
    },
    async getListPage () {
      const res = await getItem(this.form.purchaseId)
      this.data = res.data
    }
  }
}
</script>
