<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
        <el-button
            type="success"
            @click.stop="exportBranch()">导出
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_branch_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            v-if="permission.bms_branch_edit"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            v-if="permission.bms_branch_delete"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="rowDel(row)">删除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { submit } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branch',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        align: 'center',
        tip: false,
        border: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        viewBtn: true,
        index: true,
        selection: true,
        labelWidth: 200,
        searchMenuSpan: 5,
        column: [
          {
            label: '店铺名称',
            width: 150,
            prop: 'name',
            search: true
          },
          {
            label: '地址',
            prop: 'address',
            width: 200,
            editDisplay: false,
            addDisplay: false
          },
          {
            label: '经纬度',
            prop: 'map',
            type: 'map',
            hide: true
          },
          {
            label: '联系方式',
            width: 120,
            prop: 'mobile'
          },
          {
            label: '配送范围  /m',
            prop: 'scope',
            type: 'number',
            value: 5000,
            min: 500,
            step: 100,
            precision: 0,
            placeholder: '请设置配送范围'
          },
          {
            label: '面积  /m²',
            prop: 'area',
            rules: [{
              message: '请输入面积  /m²'
            }]
          },
          {
            label: '租金  元/月',
            prop: 'rent'
          },
          {
            label: '租期  /月',
            prop: 'tenancyTerm',
            type: 'number'
          },
          {
            label: '涨幅情况',
            prop: 'rentDes',
            rules: [{
              message: '请输入涨幅情况'
            }]
          },
          {
            label: '签约日期',
            width: 120,
            prop: 'signDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '到期日期',
            width: 120,
            prop: 'dueDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branch_add,
        viewBtn: this.permission.bms_branch_view,
        delBtn: this.permission.bms_branch_delete,
        editBtn: this.permission.bms_branch_edit
      }
    }
  },
  methods: {
    rowSave (row, done, loading) {
      this.form = row
      const map = row.map
      if (map) {
        this.form.center = map[1] + ' ' + map[0]
        this.form.address = map[2]
      }
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: '保存失败!'
          })
        }
      }).catch(() => {
        loading()
      })
    },
    toEdit (row) {
      this.$router.push({
        path: '/bms/branchedit',
        query: { id: row.id }
      })
    },
    toAdd () {
      this.$router.push({ path: '/bms/branchedit' })
    },
    exportBranch () {
      this.handleExport('门店信息导出')
    }
  }
}
</script>
