<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        ref="crud"
        v-model="form"
        :permission="permissionList"
        :page="page"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @refresh-change="refreshChange"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.code_delete"
            plain
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-refresh"
            @click="handleBuild(false)">代码生成
        </el-button>
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-refresh"
            @click="handleBuild(true)">初始化模块并代码生成
        </el-button>
      </template>
      <template
          slot-scope="{row}"
          slot="roleId">
        <el-tag>{{ row.roleName }}</el-tag>
      </template>
      <template
          slot-scope="{row}"
          slot="deptId">
        <el-tag>{{ row.deptName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { build } from '@/api/sys/code'
import { mapGetters } from 'vuex'
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'sys/code',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        border: true,
        selection: true,
        labelWidth: 120,
        viewBtn: true,
        dialogFullscreen: false,
        searchMenuSpan: 5,
        column: [
          {
            label: '模块名',
            prop: 'codeName',
            search: true,
            display: false,
            rules: [
              {
                required: true,
                message: '请输入模块名',
                trigger: 'blur'
              }]
          },
          {
            label: '表名',
            prop: 'tableName',
            display: false,
            rules: [
              {
                required: true,
                message: '请输入表名',
                trigger: 'blur'
              }]
          },
          {
            label: '表前缀',
            prop: 'tablePrefix',
            display: false,
            rules: [
              {
                required: true,
                message: '请输入表前缀',
                trigger: 'blur'
              }]
          },
          {
            label: '主键名',
            prop: 'pkName',
            display: false,
            rules: [
              {
                required: true,
                message: '请输入主键名',
                trigger: 'blur'
              }]
          },
          {
            label: '包名',
            prop: 'packageName',
            overHidden: true,
            display: false,
            rules: [
              {
                required: true,
                message: '请输入包名',
                trigger: 'blur'
              }]
          }
        ],
        group: [
          {
            label: '选择表',
            icon: 'el-icon-edit-outline',
            column: [
              {
                label: '表名称',
                prop: 'table_name',
                type: 'select',
                defaultIndex: 1,
                filterable: true,
                dicUrl: '/sys/code/table-list',
                dicMethod: 'get',
                change: (e) => {
                  if (!e.value) {
                    return
                  }
                  let table = e.value
                  this.form.tableName = e.value
                  this.form.tablePrefix = ''
                  if (table.startsWith('t_')) {
                    table = table.substring(2)
                    this.form.tablePrefix = 't_'
                  }
                  if (table.startsWith('T_')) {
                    table = table.substring(2)
                    this.form.tablePrefix = 'T_'
                  }
                  this.form.codeName = table.substring(0, table.indexOf('_'))
                  this.form.tablePrefix += this.form.codeName + '_'
                  this.form.codeName = this.form.codeName.toLowerCase()
                  this.form.pkName = 'id'
                  this.form.packageName = 'com.good.service.' + this.form.codeName
                  this.form.genFile = 'controller,entity,mapper,ui,service'
                }
              },
              {
                label: '所属菜单',
                prop: 'menuId',
                type: 'tree',
                dicUrl: '/sys/menu/tree',
                hide: true,
                props: {
                  value: 'id',
                  label: 'name'
                }
              }
            ]
          },
          {
            label: '代码生成信息',
            icon: 'el-icon-edit-outline',
            column: [
              {
                label: '模块名',
                prop: 'codeName',
                rules: [
                  {
                    required: true,
                    message: '请输入模块名',
                    trigger: 'blur'
                  }]
              },
              {
                label: '表名',
                prop: 'tableName',
                rules: [
                  {
                    required: true,
                    message: '请输入表名',
                    trigger: 'blur'
                  }]
              },
              {
                label: '表前缀',
                prop: 'tablePrefix',
                rules: [
                  {
                    required: true,
                    message: '请输入表前缀',
                    trigger: 'blur'
                  }]
              },
              {
                label: '主键名',
                prop: 'pkName',
                rules: [
                  {
                    required: true,
                    message: '请输入主键名',
                    trigger: 'blur'
                  }]
              },
              {
                label: '包名',
                prop: 'packageName',
                overHidden: true,
                rules: [
                  {
                    required: true,
                    message: '请输入包名',
                    trigger: 'blur'
                  }]
              },
              {
                label: '引用avue',
                prop: 'avue',
                type: 'radio',
                dicUrl: '/sys/dict/code/yes_no',
                value: 1
              },
              {
                label: '生成文件',
                prop: 'genFile',
                type: 'checkbox',
                dicUrl: '/sys/code/gen/file',
                value: 'controller,entity,mapper,service,ui'
              }
            ]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['permission']),
    permissionList () {
      return {
        addBtn: this.vaildData(this.permission.sys_code_add, false),
        addRowBtn: this.vaildData(this.permission.sys_code_add, false),
        viewBtn: this.vaildData(this.permission.sys_code_view, false),
        delBtn: this.vaildData(this.permission.sys_code_delete, false),
        editBtn: this.vaildData(this.permission.sys_code_edit, false)
      }
    }
  },
  methods: {
    handleBuild (newModule) {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('是否生成选中模块的代码?', {
        title: '代码生成确认',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return build(this.ids, newModule)
      }).then(() => {
        this.onLoad(this.page)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.$refs.crud.toggleSelection()
      })
    }
  }
}
</script>
<style>
</style>
