<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.vms_testproject_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/testproject',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        delBtn: false,
        selection: true,
        labelWidth: 200,
        searchSpan: 7,
        searchMenuSpan: 6,
        column: [
          {
            label: '仓库品控检测项目',
            prop: 'project',
            search: true,
            searchLabelWidth: 135,
            rules: [{
              required: true,
              message: '请输入仓库品控检测项目'
            }]
          }, {
            label: '检测标准',
            prop: 'testStandard'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_testproject_add,
        viewBtn: this.permission.vms_testproject_view,
        delBtn: this.permission.vms_testproject_delete,
        editBtn: this.permission.vms_testproject_edit
      }
    }
  },
  methods: {
  }
}
</script>
