<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/aftersale',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menuWidth: 90,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 5,
        searchSpan: 10,
        column: [
          {
            label: '用户名称',
            prop: 'memberName',
            rules: [{
              required: true,
              message: '请输入用户名称'
            }]
          },
          {
            label: '账号类型',
            prop: 'accountType',
            search: true,
            filterable: true,
            type: 'select',
            dicData: [{
              value: 'WECHAT',
              label: '微信'
            },
            {
              value: 'ALIPAY',
              label: '支付宝'
            },
            {
              value: 'WALLET',
              label: '余额支付'
            },
            {
              value: 'BANK_TRANSFER',
              label: '线下转账'
            }]
          },
          {
            label: '申请原因',
            prop: 'reason'
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '实际退款金额',
            prop: 'actualRefundPrice',
            rules: [{
              required: true,
              message: '请输入实际退款金额'
            }]
          },
          {
            label: '评价图片',
            prop: 'afterSaleImage',
            dataType: 'string',
            type: 'upload',
            listType: 'picture-card',
            oss: 'ali',
            rules: [{
              required: true,
              message: '请输入评价图片'
            }]
          },
          {
            label: '申请退款金额',
            prop: 'applyRefundPrice',
            rules: [{
              required: true,
              message: '请输入申请退款金额'
            }]
          },
          {
            label: '商家备注',
            prop: 'auditRemark',
            rules: [{
              required: true,
              message: '请输入商家备注'
            }]
          },
          {
            label: '实际金额',
            prop: 'flowPrice',
            rules: [{
              required: true,
              message: '请输入实际金额'
            }]
          },
          {
            label: '商品图片',
            prop: 'goodsImage',
            dataType: 'string',
            type: 'upload',
            listType: 'picture-card',
            oss: 'ali',
            rules: [{
              required: true,
              message: '请输入商品图片'
            }]
          },
          {
            label: '商品名称',
            prop: 'goodsName',
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '申请数量',
            prop: 'num',
            rules: [{
              required: true,
              message: '请输入申请数量'
            }]
          },
          {
            label: '订单编号',
            prop: 'orderSn',
            rules: [{
              required: true,
              message: '请输入订单编号'
            }]
          },
          {
            label: '问题描述',
            prop: 'problemDesc',
            rules: [{
              required: true,
              message: '请输入问题描述'
            }]
          },
          {
            label: '退款时间',
            prop: 'refundTime',
            rules: [{
              required: true,
              message: '请输入退款时间'
            }]
          },
          {
            label: '退款方式',
            prop: 'refundWay',
            search: true,
            type: 'select',
            filterable: true,
            dicData: [{
              label: '原路退回',
              value: 'ORIGINAL'
            },
            {
              label: '线下支付',
              value: 'OFFLINE'
            }]
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '售后单状态',
            prop: 'serviceStatus',
            search: true,
            searchLabelWidth: 95,
            type: 'select',
            filterable: true,
            dicData: [{
              label: '申请中',
              value: 'APPLY'
            },
            {
              label: '已通过',
              value: 'PASS'
            },
            {
              label: '已拒绝',
              value: 'REFUSE'
            },
            {
              label: '待卖家收货',
              value: 'BUYER_RETURN'
            },
            {
              label: '卖家确认收货',
              value: 'SELLER_CONFIRM'
            },
            {
              label: '卖家终止售后',
              value: 'SELLER_TERMINATION'
            },
            {
              label: '买家取消售后',
              value: 'BUYER_CANCEL'
            },
            {
              label: '等待平台退款',
              value: 'WAIT_REFUND'
            },
            {
              label: '完成',
              value: 'COMPLETE'
            }]
          },
          {
            label: '售后类型',
            prop: 'serviceType',
            search: true,
            type: 'select',
            dicData: [{
              label: '退款',
              value: 'RETURN_MONEY'
            },
            {
              label: '退货',
              value: 'RETURN_GOODS'
            }]
          },
          {
            label: '售后服务单号',
            prop: 'sn',
            rules: [{
              required: true,
              message: '请输入售后服务单号'
            }]
          },
          {
            label: '交易编号',
            prop: 'tradeSn',
            rules: [{
              required: true,
              message: '请输入交易编号'
            }]
          },
          {
            label: '订单货物编号',
            prop: 'orderItemSn',
            rules: [{
              required: true,
              message: '请输入订单货物编号'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_aftersale_add,
        viewBtn: this.permission.shop_aftersale_view,
        delBtn: this.permission.shop_aftersale_delete,
        editBtn: this.permission.shop_aftersale_edit
      }
    }
  },
  methods: {
  }
}
</script>
