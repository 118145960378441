<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            v-model="form"
            :permission="permissionList"
            >
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-back"
            plain
            @click="toBack()">返回
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getItemPage } from '@/api/shop/orderitem'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/orderitem',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menu: false,
        addBtn: false,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '订单编号',
            prop: 'orderSn'
          },
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: '单价',
            prop: 'unitPrice'
          },
          {
            label: '商品数量',
            prop: 'num'
          },
          {
            label: '品名',
            prop: 'name'
          },
          {
            label: '物料编号',
            prop: 'fullCode'
          },
          {
            label: '下单时间',
            prop: 'paymentTime'
          },
          {
            label: '订单类型',
            prop: 'clientType',
            type: 'select',
            filterable: true,
            search: true,
            dicUrl: '/sys/dict/code/order_client_type'
          },
          {
            label: '微信名',
            prop: 'nickName'
          },
          {
            label: '收件人姓名',
            prop: 'consigneeName'
          },
          {
            label: '收件人电话',
            prop: 'consigneeMobile'
          },
          {
            label: '收件人地址',
            prop: 'consigneeAddressPath'
          },
          {
            label: '详细地址',
            prop: 'consigneeDetail'
          },
          {
            label: '总价格',
            prop: 'flowPrice'
          },
          {
            label: '支付方式',
            prop: 'paymentMethod',
            dicUrl: '/sys/dict/code/order_payment_method'
          },
          {
            label: '配送方式',
            prop: 'deliveryMethod',
            dicUrl: '/sys/dict/code/order_delivery_method'
          },
          {
            label: '订单状态',
            prop: 'orderStatus',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dict/code/order_order_status'
          },
          {
            label: '客户预约送达时间',
            prop: 'deliveryTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '货运状态',
            prop: 'deliverStatus',
            type: 'select',
            filterable: true,
            dicUrl: '/sys/dict/code/order_deliver_status'
          },
          {
            label: '折扣价钱',
            prop: 'discountPrice'
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_orderitem_add,
        viewBtn: this.permission.shop_orderitem_view,
        delBtn: this.permission.shop_orderitem_delete,
        editBtn: this.permission.shop_orderitem_edit
      }
    }
  },
  created () {
    this.form.orderSn = this.$route.query.sn
    if (this.form.orderSn) {
      this.page.orderSn = this.form.orderSn
      console.log(this.page.orderSn)
      this.onLoadItem(this.page)
    }
  },
  methods: {
    onLoadItem (page) {
      this.search.orderSn = page.orderSn
      getItemPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    toBack () {
      this.$router.push({ path: '/shop/order' })
    }
  }
}
</script>
