<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChangePage"
            @search-reset="searchResetPage"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoadPage">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportActualPurchase">导出
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-view"
            size="small"
            @click.stop="toView(row)">查看
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getListPage, exportActualExcel } from '@/api/bms/branchpurchase'
import { formatDate } from '@/util/date'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchpurchase',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '实际采购单号',
            prop: 'sn',
            search: true,
            searchLabelWidth: 100,
            rules: [{
              required: true,
              message: '请输入单号'
            }]
          },
          {
            label: '预估采购单号',
            prop: 'estimateSn'
          },
          {
            label: '实际采购日期',
            prop: 'createTime',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            editDisabled: true,
            addDisabled: true,
            search: true,
            searchLabelWidth: 100
          },
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            search: true
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            filterable: true,
            dicData: [{
              label: '草稿',
              value: 0
            },
            {
              label: '已提交',
              value: 1
            },
            {
              label: '已提交',
              value: 5
            }]
          },
          {
            label: '品名总数',
            prop: 'sumMaterial'
          },
          {
            label: '总重量',
            prop: 'sumWeight'
          },
          {
            label: '要求到店日期',
            prop: 'needDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '品名总数差异',
            prop: 'sumMaterialEstimate'
          },
          {
            label: '总重量差异',
            prop: 'sumWeightEstimate'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.bms_branchpurchase_actual
      }
    }
  },
  methods: {
    toView (row) {
      this.$router.push({
        path: '/bms/branchpurchaseitem',
        query: { purchaseId: row.id }
      })
    },
    async onLoadPage (page) {
      getListPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    searchChangePage (params, done) {
      this.search = params
      this.onLoadPage(this.page)
      done()
    },
    searchResetPage () {
      this.search = {}
      this.onLoadPage(this.page)
    },
    exportActualPurchase () {
      this.handleActualExport('实际采购单信息导出')
    },
    handleActualExport (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportActualExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    }
  }
}
</script>
