<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>供应商基础信息</h2>
    </div>
    <avue-form :option="option" v-model="form" @submit="handleSubmit">
      <template slot="menuForm">
        <el-button @click="toVendor">返回</el-button>
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import { submit, getDetail } from '@/api/vms/vendor'

export default {
  data () {
    return {
      module: 'vms/vendor',
      form: {},
      search: {},
      option: {
        labelWidth: 120,
        column: [
          {
            label: '供应商名称',
            prop: 'vendorName',
            rules: [{
              message: '请输入供应商名称',
              required: true
            }]
          },
          {
            label: '供应商简称',
            prop: 'vendorShortName'
          },
          {
            label: '供应商代码',
            prop: 'vendorCode',
            rules: [{
              message: '请输入供应商代码',
              required: true
            }]
          },
          {
            label: '供应商类别',
            prop: 'category',
            type: 'select',
            filterable: true,
            dataType: 'number',
            search: true,
            dicUrl: '/vms/dic/categoryDic',
            rules: [{
              required: true,
              message: '请输入供应商类别'
            }]
          },
          {
            label: '供应商级别',
            prop: 'level',
            type: 'select',
            filterable: true,
            dataType: 'number',
            search: true,
            dicUrl: '/vms/dic/levelDic',
            rules: [{
              required: true,
              message: '请输入级别'
            }]
          },
          {
            label: '采购员',
            prop: 'buyer',
            rules: [{
              message: '请输入采购员',
              required: true
            }]
          },
          {
            label: '供应商地址',
            prop: 'address',
            span: 24,
            rules: [{
              message: '请输入供应商地址',
              required: true
            }]
          },
          {
            label: '签约起始日期',
            prop: 'signStartDate',
            valueFormat: 'yyyy-MM-dd',
            type: 'date',
            rules: [{
              message: '请输入签约起始日期',
              required: true
            }]
          },
          {
            label: '签约结束日期',
            prop: 'signEndDate',
            valueFormat: 'yyyy-MM-dd',
            type: 'date',
            rules: [{
              message: '请输入签约结束日期',
              required: true
            }]
          },
          {
            label: '纳税人识别号',
            prop: 'nsrsbh',
            rules: [{
              message: '请输入纳税人识别号',
              required: true
            }]
          },
          {
            label: '供应商电话',
            prop: 'phone',
            rules: [{
              message: '请输入供应商电话',
              required: true
            }]
          },
          {
            label: '供应商开票地址',
            prop: 'billingAddress',
            span: 24,
            rules: [{
              message: '请输入供应商开票地址',
              required: true
            }]
          },
          {
            label: '供应商开户银行',
            prop: 'bankName',
            rules: [{
              message: '请输入供应商开户银行',
              required: true
            }]
          },
          {
            label: '供应商银行账号',
            prop: 'bankAccount',
            rules: [{
              message: '请输入供应商银行账号',
              required: true
            }]
          },
          {
            label: '供应商结款日',
            prop: 'accountDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '供应商账期/天',
            prop: 'paymentDays',
            max: 31,
            type: 'number'
          },
          {
            label: '供应商联系人',
            prop: 'contactList',
            type: 'dynamic',
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              column: [{
                label: '联系人姓名',
                prop: 'name'
              }, {
                label: '联系人电话',
                prop: 'phone'
              }]
            },
            rules: [{
              message: '请输入供应商联系人',
              required: true
            }]
          },
          {
            label: '营业执照',
            prop: 'businessLicense',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '食品经营许可证',
            prop: 'foodBusinessLicense',
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '其他证件照',
            prop: 'otherLicense',
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            type: 'upload',
            oss: 'ali'
          },
          {
            label: '备注',
            prop: 'remark',
            span: 24
          }
        ]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    handleSubmit () {
      submit(this.form).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.toVendor()
      })
    },
    toVendor () {
      this.$router.push({ path: '/vms/vendor' })
    }
  }
}
</script>

<style lang="scss">
.el-empty{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
