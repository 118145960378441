<template>
  <basic-container>
    <avue-crud
        :row-style="rowStyle"
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="update"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="primary" size="small" @click.stop="exportMaterial()">导出</el-button>
        <el-button type="primary" size="small" @click.stop="handleMaterialModel()">模板下载</el-button>
        <el-upload
            class="filter-item"
            :show-file-list="false"
            size="small"
            :action="handleImport()"
            :headers="uploadHeaders()"
            :on-success="uploadMaterial"
          style="display: inline-block">
          <el-button type="warning" icon="el-icon-bottom">导入</el-button>
        </el-upload>
      </template>
      <template slot-scope="{row,index}" slot="menu">
        <el-button icon="el-icon-edit" type="text" v-if="row.deleted === 0" :index="index" @click="handleEdit(row,index)">编辑</el-button>
        <el-button icon="el-icon-switch-button" type="text" v-if="row.deleted === 0" :index="index"   @click="frozen(row)">冻结</el-button>
        <el-button icon="el-icon-switch-button" type="text" v-if="row.deleted === 1" :index="index" @click="frozen(row)">取消冻结</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { updateById, logic } from '@/api/vms/material'
import { modelExcel } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      editPermission: true,
      module: 'vms/material',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        indexLabel: '序号',
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        searchMenuSpan: 4,
        searchSpan: 5,
        column: [
          {
            label: '品名',
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入品名'
            }]
          },
          {
            label: '物料号',
            prop: 'fullCode',
            search: true,
            rules: [{
              required: true,
              message: '请输入物料号'
            }]
          },
          {
            label: '基本单位',
            prop: 'unit',
            rules: [{
              required: true,
              message: '请输入基本单位'
            }]
          },
          {
            label: '辅助单位',
            prop: 'assistUnit'
          },
          {
            label: '类别',
            prop: 'level',
            type: 'select',
            filterable: true,
            dicUrl: 'sys/dict/code/material_level',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            search: true
          },
          {
            label: '备注',
            prop: 'remark'
          },
          {
            label: '状态',
            prop: 'deleted',
            type: 'select',
            filterable: true,
            value: 0,
            addDisabled: true,
            editDisabled: true,
            dicData: [{
              value: 0,
              label: '正常'
            }, {
              value: 1,
              label: '冻结'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_material_add,
        viewBtn: this.permission.vms_material_view,
        delBtn: this.permission.vms_material_delete,
        editBtn: this.permission.vms_material_edit
      }
    }
  },
  methods: {
    frozen (row) {
      const msg = this.form.deleted === 1 ? '取消冻结' : '冻结'
      this.$confirm('确认' + msg + '吗？', '提示', {}).then(() => {
        logic(row.id).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '冻结成功!'
            })
            this.onLoad(this.page)
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleEdit (row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    async update (row, done, loading) {
      try {
        const res = await updateById(row)
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          done()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
          done()
        }
      } catch (e) {
        loading()
      }
    },
    exportMaterial () {
      this.handleExport('物料信息导出')
    },
    handleMaterialModel () {
      modelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '物料信息模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    },
    uploadMaterial (res) {
      console.log(res)
      if (res.success) {
        this.$message({ message: res.msg, type: 'success' })
        this.onLoad(this.page)
      } else {
        this.$message({ message: res.msg, type: 'error' })
      }
    },
    rowStyle ({ row }) {
      if (row.deleted === 1) {
        return {
          backgroundColor: '#E0DEDE'
        }
      }
    }
  }
}
</script>
