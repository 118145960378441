import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/vms/material/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getListInActivity = (current, size, params) => {
  return request({
    url: '/vms/material/page/' + params.type,
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/vms/material/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/vms/material/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/vms/material/submit',
    method: 'post',
    data: row
  })
}

export const updateById = (row) => {
  return request({
    url: '/vms/material/updateById',
    method: 'post',
    data: row
  })
}
export const logic = (id) => {
  return request({
    url: '/vms/material/logic/' + id,
    method: 'post'
  })
}
