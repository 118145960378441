<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
        <el-button
            type="success"
            @click.stop="exportVendor()">导出
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.vms_vendor_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="toPasture(row)">关联牧场
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/vendor',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        editBtn: false,
        delBtn: true,
        viewBtn: true,
        selection: true,
        searchBtn: true,
        labelWidth: 200,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '供应商全称',
            prop: 'vendorName',
            search: true,
            searchLabelWidth: 120,
            rules: [{
              message: '请输入供应商名称',
              required: true
            }]
          },
          {
            label: '供应商简称',
            prop: 'vendorShortName'
          },
          {
            label: '供应商代码',
            prop: 'vendorCode',
            rules: [{
              message: '请输入供应商名称',
              required: true
            }]
          },
          {
            label: '供应商类别',
            prop: 'category',
            type: 'select',
            filterable: true,
            dataType: 'number',
            search: true,
            searchLabelWidth: 100,
            dicUrl: '/vms/dic/categoryDic',
            rules: [{
              required: true,
              message: '请输入级别'
            }]
          },
          {
            label: '供应商级别',
            prop: 'level',
            type: 'select',
            filterable: true,
            dataType: 'number',
            search: true,
            searchLabelWidth: 100,
            dicUrl: '/vms/dic/levelDic',
            rules: [{
              required: true,
              message: '请输入级别'
            }]
          },
          {
            label: '供应商地址',
            prop: 'address',
            type: 'textarea',
            span: 24,
            rules: [{
              required: true,
              message: '请输入供应商地址'
            }]
          },
          {
            label: '签约起始日期',
            prop: 'signStartDate',
            type: 'date',
            rules: [{
              required: true,
              message: '请输入签约起始日期'
            }]
          },
          {
            label: '签约结束日期',
            prop: 'signEndDate',
            type: 'date',
            rules: [{
              required: true,
              message: '请输入签约结束日期'
            }]
          },
          {
            label: '采购员',
            prop: 'buyer',
            rules: [{
              required: true,
              message: '请输入采购员'
            }]
          },
          {
            label: '供应商纳税人识别号',
            prop: 'nsrsbh',
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商纳税人识别号'
            }]
          },
          {
            label: '供应商开票地址',
            prop: 'billingAddress',
            type: 'textarea',
            span: 24,
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商开票地址'
            }]
          },
          {
            label: '供应商电话',
            prop: 'phone',
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商电话'
            }]
          },
          {
            label: '供应商开户银行',
            prop: 'bankName',
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商开户银行'
            }]
          },
          {
            label: '供应商银行账号',
            prop: 'bankAccount',
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商银行账号'
            }]
          },
          {
            label: '供应商结款日',
            prop: 'accountDate',
            type: 'number',
            pickerOptions: {
            },
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商结款日'
            }]
          },
          {
            label: '供应商账期',
            prop: 'paymentDays',
            type: 'number',
            hide: true,
            rules: [{
              required: true,
              message: '请输入供应商账期'
            }]
          },
          {
            label: '营业执照',
            prop: 'businessLicense',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '食品经营许可证',
            prop: 'foodBusinessLicense',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '其他证件照',
            prop: 'otherLicense',
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            type: 'upload',
            oss: 'ali',
            hide: true
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_vendor_add,
        viewBtn: this.permission.vms_vendor_view,
        delBtn: this.permission.vms_vendor_delete,
        editBtn: this.permission.vms_vendor_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/vms/vendoredit',
        query: { id: row.id }
      })
    },
    toAdd () {
      this.$router.push({ path: '/vms/vendoredit' })
    },
    exportVendor () {
      this.handleExport('供应商信息导出')
    },
    toPasture (row) {
      this.$router.push({
        path: '/vms/pasture',
        query: { vendorId: row.id }
      })
    }
  }
}
</script>
