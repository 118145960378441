<template>
  <basic-container>
    <avue-crud
        :defaults.sync="defaults"
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.vms_pasture_delete"
            @click="handleDelete">删 除
        </el-button>
        <el-button
            type="success"
            @click.stop="exportPasture()">导出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      defaults: {},
      module: 'vms/pasture',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },

      selectionList: [],
      option: {
        labelWidth: 120,
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '牧场名称',
            prop: 'pastureName',
            search: true,
            rules: [{
              required: true,
              message: '请输入牧场名称'
            }]
          },
          {
            label: '所属供应商',
            prop: 'vendorId',
            type: 'select',
            filterable: true,
            search: true,
            searchLabelWidth: 100,
            dicUrl: '/vms/vendor/dict'
          },
          {
            label: '牧场地址',
            prop: 'address',
            rules: [{
              required: true,
              message: '请输入牧场地址'
            }]
          },
          {
            label: '牧场手机号',
            prop: 'phone',
            rules: [{
              required: true,
              message: '请输入牧场手机号'
            }]
          },
          {
            label: '牧场联系人',
            prop: 'username',
            rules: [{
              required: true,
              message: '请输入牧场联系人'
            }]
          },
          {
            label: '面积',
            prop: 'area',
            hide: true,
            type: 'number'
          },
          {
            label: '牛头数',
            prop: 'beefCount',
            type: 'number'
          },
          {
            label: '存栏量',
            prop: 'breedingStock',
            hide: true,
            type: 'number'
          },
          {
            label: '其他证件照',
            prop: 'otherLicense',
            hide: true,
            dataType: 'string',
            listType: 'picture-card',
            span: 24,
            type: 'upload',
            oss: 'ali'
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_pasture_add,
        viewBtn: this.permission.vms_pasture_view,
        delBtn: this.permission.vms_pasture_delete,
        editBtn: this.permission.vms_pasture_edit
      }
    }
  },
  mounted () {
    this.form.vendorId = this.$route.query.vendorId
    if (this.form.vendorId) {
      if (this.$refs.crud) {
        this.$refs.crud.rowAdd()
      }
    }
  },
  watch: {
    'form.vendorId' (val) {
      if (val) {
        this.defaults.vendorId.addDisabled = true
      }
    }
  },
  methods: {
    exportPasture () {
      this.handleExport('牧场信息导出')
    }
  }
}
</script>

