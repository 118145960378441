<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.vms_cattletestresult_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/cattletestresult',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        delBtn: false,
        selection: true,
        labelWidth: 200,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '耳标',
            prop: 'cattleId',
            type: 'select',
            filterable: true,
            dicUrl: 'vms/cattle/earTagDict',
            search: true,
            rules: [{
              required: true,
              message: '请输入耳标'
            }]
          },
          {
            label: '仓库品控检测项目',
            prop: 'projectId',
            type: 'select',
            filterable: true,
            dicUrl: '/vms/cattletestresult/testProject'
          },
          {
            label: '结果',
            prop: 'result',
            rules: [{
              required: true,
              message: '请输入结果'
            }]
          },
          {
            label: '检测日期',
            prop: 'testDate',
            type: 'date',
            editDisabled: true,
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              required: true,
              message: '请输入检测日期'
            }]
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_cattletestresult_add,
        viewBtn: this.permission.vms_cattletestresult_view,
        delBtn: this.permission.vms_cattletestresult_delete,
        editBtn: this.permission.vms_cattletestresult_edit
      }
    }
  },
  mounted () {
    this.form.cattleId = this.$route.query.cattleId
    if (this.form.cattleId) {
      if (this.$refs.crud) {
        this.$refs.crud.rowAdd()
      }
    }
  },
  methods: {
  }
}
</script>
