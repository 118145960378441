import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/bms/branchpurchase/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/bms/branchpurchase/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/bms/branchpurchase/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/bms/branchpurchase/submit',
    method: 'post',
    data: row
  })
}

export const getListPage = (current, size, params) => {
  return request({
    url: '/bms/branchpurchase/actual',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const exportActualExcel = (module, current, size, params) => {
  return request({
    url: module + '/exportActual',
    method: 'get',
    responseType: 'blob',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getShopListPage = (current, size, params) => {
  return request({
    url: '/bms/branchpurchase/shopPage',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getLast = (id) => {
  return request({
    url: '/bms/branchpurchase/last',
    method: 'get',
    params: {
      id
    }
  })
}

export const submitLast = (row) => {
  return request({
    url: '/bms/branchpurchase/submitLast',
    method: 'post',
    data: row
  })
}
