<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>门店配置</h2>
    </div>
    <avue-form :option="option" v-model="form" :defaults.sync="defaults" @submit="rowSave">
    </avue-form>
      <div v-show="businessHoursVisible">
          <el-row :gutter="20">
            <el-form>
              <el-col :span="6">
                <el-form-item label="上午上班：" prop="start1">
                  <el-time-picker format="HH:mm" value-format="HH:mm" v-model="businessHoursParams.start1"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="上午下班：" prop="end1">
                  <el-time-picker format="HH:mm" value-format="HH:mm" v-model="businessHoursParams.end1"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="下午上班：" prop="start2">
                  <el-time-picker format="HH:mm" value-format="HH:mm" v-model="businessHoursParams.start2"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="下午下班：" prop="end2">
                  <el-time-picker format="HH:mm" value-format="HH:mm" v-model="businessHoursParams.end2"></el-time-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="每班时长(分钟)：" prop="length">
                  <el-input-number :step="10" :min="10" :max="1440" v-model="businessHoursParams.length"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="交接班时长(分钟)：" prop="step">
                  <el-input-number :step="1" :min="0" :max="1440" v-model="businessHoursParams.step"></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-button
                    icon="el-icon-edit"
                    size="small"
                    style="display: inline-block;margin: 40px 100px 10px 0;vertical-align: middle"
                    type="primary"
                    @click="getDefaults('businessHours')">初始化
                </el-button>
              </el-col>
            </el-form>
          </el-row>
          <el-row>
            <avue-crud :data="businessHoursData" :option="businessHoursOption" @row-save="businessHourAdd" @row-update="businessHourUpdate" @row-del="businessHourDel">
            </avue-crud>
          </el-row>
        </div>
      <div v-show="deliveryVisible">
        <el-row :gutter="20">
          <el-form>
            <el-col :span="6">
              <el-form-item label="上午配送开始：" prop="start1">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="deliveryParams.start1"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上午配送截止：" prop="end1">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="deliveryParams.end1"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下午配送开始：" prop="start2">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="deliveryParams.start2"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下午配送截止：" prop="end2">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="deliveryParams.end2"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="每班时长(分钟)：" prop="length">
                <el-input-number :step="10" :min="10" :max="1440" v-model="deliveryParams.length"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="交接班时长(分钟)：" prop="step">
                <el-input-number :step="1" :min="0" :max="1440" v-model="deliveryParams.step"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-edit"
                  size="small"
                  style="display: inline-block;margin: 40px 100px 10px 0;vertical-align: middle"
                  type="primary"
                  @click="getDefaults('delivery')">初始化
              </el-button>
            </el-col>
          </el-form>
        </el-row>
        <el-row>
          <avue-crud :data="deliveryData" :option="deliveryOption" @row-save="deliveryAdd" @row-update="deliveryUpdate" @row-del="deliveryDel">
          </avue-crud>
        </el-row>
      </div>
      <div v-show="selfDeliveryVisible">
        <el-row :gutter="20">
          <el-form>
            <el-col :span="6">
              <el-form-item label="上午自提开始：" prop="start1">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="selfDeliveryParams.start1"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="上午自提结束：" prop="end1">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="selfDeliveryParams.end1"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下午自提开始：" prop="start2">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="selfDeliveryParams.start2"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下午自提结束：" prop="end2">
                <el-time-picker format="HH:mm" value-format="HH:mm" v-model="selfDeliveryParams.end2"></el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="每班时长(分钟)：" prop="length">
                <el-input-number :step="10" :min="10" :max="1440" v-model="selfDeliveryParams.length"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="交接班时长(分钟)：" prop="step">
                <el-input-number :step="1" :min="0" :max="1440" v-model="selfDeliveryParams.step"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-button
                  icon="el-icon-edit"
                  size="small"
                  style="display: inline-block;margin: 40px 100px 10px 0;vertical-align: middle"
                  type="primary"
                  @click="getDefaults('selfDelivery')">初始化
              </el-button>
            </el-col>
          </el-form>
        </el-row>
        <el-row>
          <avue-crud :data="selfDeliveryData" :option="selfDeliveryOption" @row-save="selfDeliveryAdd" @row-update="selfDeliveryUpdate" @row-del="selfDeliveryDel">
          </avue-crud>
        </el-row>
      </div>
    <div>
      <el-row :gutter="20">
        <el-form>
          <el-col :span="12">
            <el-button
                size="small"
                style="display: inline-block;margin: 40px 100px 10px 0;vertical-align: middle"
                type="primary"
                @click="toBranchConfig">返回
            </el-button>
          </el-col>
        </el-form>
      </el-row>
    </div>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getDetail } from '@/api/bms/branchconfig'
import { getDefaults } from '@/api/bms/branchconfigdetail'
import { deepClone } from '@/util/index'
import { submit } from '@/api/common'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchconfig',
      form: {
        businessHours: undefined,
        delivery: undefined,
        selfDelivery: undefined
      },
      defaults: {
        refundTime: null,
        tomorrowRefundTime: null,
        isDeliveredAllDay: null,
        isSelfDeliveredAllDay: null
      },
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      businessHoursVisible: false,
      deliveryVisible: false,
      selfDeliveryVisible: false,
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        align: 'center',
        labelWidth: 150,
        dialogFullscreen: true,
        column: [
          {
            label: '营业时间起',
            width: 100,
            prop: 'businessHoursStart',
            type: 'time',
            value: '09:00',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置营业起始时间'
          },
          {
            label: '营业时间止',
            width: 100,
            prop: 'businessHoursEnd',
            type: 'time',
            value: '22:00',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置营业结束时间'
          },
          {
            label: '全天营业',
            prop: 'isAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天营业'
          },
          {
            label: '允许退款',
            prop: 'isRefunded',
            type: 'switch',
            value: 0,
            disabled: true,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天营业'
          },
          {
            label: '当天退款截止(分钟)',
            prop: 'refundTime',
            type: 'number',
            value: '60',
            min: 0,
            step: 5,
            placeholder: '请设置送货前多少分钟不允许退款'
          },
          {
            label: '隔天送货退款截止',
            prop: 'tomorrowRefundTime',
            type: 'time',
            value: '20:00',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置隔天送货退款截止时间'
          },
          {
            label: '配送',
            prop: 'isDelivered',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不提供' }, { value: 1, label: '提供' }],
            placeholder: '请设置门店是否配送'
          },
          {
            label: '全天配送',
            prop: 'isDeliveredAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '否' }, { value: 1, label: '是' }],
            placeholder: '请设置门店是否全天配送'
          },
          {
            label: '允许自提',
            prop: 'isSelfDelivered',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不允许' }, { value: 1, label: '允许' }],
            placeholder: '请设置门店是否允许自提'
          },
          {
            label: '全天自提',
            prop: 'isSelfDeliveredAllDay',
            type: 'switch',
            value: 1,
            dicData: [{ value: 0, label: '不允许' }, { value: 1, label: '允许' }],
            placeholder: '请设置门店是否全天允许自提'
          }
        ]
      },
      data: [],
      businessHoursOption: {
        title: '营业时间段配置明细',
        align: 'center',
        column: [
          {
            label: '开始时间',
            prop: 'start',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置开始时间',
            rules: [{
              required: true,
              message: '请设置开始时间'
            }]
          },
          {
            label: '截止时间',
            prop: 'end',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置截止时间',
            rules: [{
              required: true,
              message: '请设置截止时间'
            }]
          }
        ]
      },
      businessHoursParams: {
        start1: '09:00',
        end1: '13:00',
        start2: '14:00',
        end2: '22:00',
        length: 240,
        step: 0
      },
      deliveryOption: {
        title: '配送时间段配置明细',
        align: 'center',
        column: [
          {
            label: '开始时间',
            prop: 'start',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置开始时间',
            rules: [{
              required: true,
              message: '请设置开始时间'
            }]
          },
          {
            label: '截止时间',
            prop: 'end',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置截止时间',
            rules: [{
              required: true,
              message: '请设置截止时间'
            }]
          }
        ]
      },
      deliveryParams: {
        start1: '09:00',
        end1: '13:00',
        start2: '14:00',
        end2: '22:00',
        length: 60,
        step: 0,
        refundTime: 60
      },
      selfDeliveryOption: {
        title: '自提时间段配置明细',
        align: 'center',
        column: [
          {
            label: '开始时间',
            prop: 'start',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置开始时间',
            rules: [{
              required: true,
              message: '请设置开始时间'
            }]
          },
          {
            label: '截止时间',
            prop: 'end',
            type: 'time',
            format: 'HH:mm',
            valueFormat: 'HH:mm',
            placeholder: '请设置截止时间',
            rules: [{
              required: true,
              message: '请设置截止时间'
            }]
          }
        ]
      },
      selfDeliveryParams: {
        start1: '09:00',
        end1: '13:00',
        start2: '14:00',
        end2: '22:00',
        length: 60,
        step: 0
      }
    }
  },
  watch: {
    'form.isAllDay': {
      handler: function (val) {
        // 非全天营业时，需要配置营业时间段
        if (val) {
          this.businessHoursVisible = false
        } else {
          this.businessHoursVisible = true
        }
      },
      deep: true
    },
    'form.isRefunded': {
      handler: function (val) {
        // 允许退款，需要设置当天和隔天退款截止时间
        if (val) {
          this.defaults.refundTime.display = true
          this.defaults.tomorrowRefundTime.display = true
        } else {
          this.defaults.refundTime.display = false
          this.defaults.tomorrowRefundTime.display = false
        }
      },
      deep: true
    },
    'form.isDelivered': {
      handler: function (val) {
        // 提供配送服务时，需要设置是否全天配送
        if (val) {
          this.defaults.isDeliveredAllDay.display = true
        } else {
          this.defaults.isDeliveredAllDay.display = false
        }
      },
      deep: true
    },
    'form.isDeliveredAllDay': {
      handler: function (val) {
        // 非全天配送时，需要配置配送时间段
        if (val) {
          this.deliveryVisible = false
        } else {
          this.deliveryVisible = true
        }
      },
      deep: true
    },
    'form.isSelfDelivered': {
      handler: function (val) {
        // 提供自提服务时，需要设置是否全天自提
        if (val) {
          this.defaults.isSelfDeliveredAllDay.display = true
        } else {
          this.defaults.isSelfDeliveredAllDay.display = true
        }
      },
      deep: true
    },
    'form.isSelfDeliveredAllDay': {
      handler: function (val) {
        // 非全天自提时，需要配置自提时间段
        if (val) {
          this.selfDeliveryVisible = false
        } else {
          this.selfDeliveryVisible = true
        }
      },
      deep: true
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchconfig_add,
        viewBtn: this.permission.bms_branchconfig_view,
        delBtn: this.permission.bms_branchconfig_delete,
        editBtn: this.permission.bms_branchconfig_edit
      }
    },
    businessHoursData () {
      return this.form.businessHours || []
    },
    deliveryData () {
      return this.form.delivery || []
    },
    selfDeliveryData () {
      return this.form.selfDelivery || []
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    rowSave (row, done, loading) {
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
          this.$router.push({ path: '/bms/branchconfig' })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    getDefaults (name) {
      const params = this[name + 'Params']
      if (name === 'delivery') {
        params.refundTime = this.form.refundTime
      }
      getDefaults(params).then(res => {
        if (res.success) {
          this.form[name] = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    businessHourAdd (row, done) {
      this.businessHoursData.push(row)
      this.form.businessHours = this.businessHoursData
      done()
    },
    businessHourUpdate (row, index, done) {
      const data = deepClone(this.form.businessHours)
      data[index] = row
      this.form.businessHours = data
      done()
    },
    businessHourDel (row, index) {
      const data = deepClone(this.form.businessHours)
      data.splice(index, 1)
      this.form.businessHours = data
    },
    deliveryAdd (row, done) {
      this.deliveryData.push(row)
      this.form.delivery = this.deliveryData
      done()
    },
    deliveryUpdate (row, index, done) {
      const data = deepClone(this.form.delivery)
      data[index] = row
      this.form.delivery = data
      done()
    },
    deliveryDel (row, index) {
      const data = deepClone(this.form.delivery)
      data.splice(index, 1)
      this.form.delivery = data
    },
    selfDeliveryAdd (row, done) {
      this.selfDeliveryData.push(row)
      this.form.selfDelivery = this.selfDeliveryData
      done()
    },
    selfDeliveryUpdate (row, index, done) {
      const data = deepClone(this.form.selfDelivery)
      data[index] = row
      this.form.selfDelivery = data
      done()
    },
    selfDeliveryDel (row, index) {
      const data = deepClone(this.form.selfDelivery)
      data.splice(index, 1)
      this.form.selfDelivery = data
    },
    toBranchConfig () {
      this.$router.push({ path: '/bms/branchconfig' })
    }
  }
}
</script>
