<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_giftdetails_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/giftdetails',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '赠品活动编号',
            prop: 'giftId',
            rules: [{
              required: true,
              message: '请输入赠品活动编号'
            }]
          },
          {
            label: '类型，0：实物，1：物料',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入类型，0：实物，1：物料'
            }]
          },
          {
            label: '物料配置ID',
            prop: 'materialId',
            rules: [{
              required: true,
              message: '请输入物料配置ID'
            }]
          },
          {
            label: '赠品名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入赠品名称'
            }]
          },
          {
            label: '赠品图片',
            prop: 'img',
            rules: [{
              required: true,
              message: '请输入赠品图片'
            }]
          },
          {
            label: '数量',
            prop: 'num',
            rules: [{
              required: true,
              message: '请输入数量'
            }]
          },
          {
            label: '单位',
            prop: 'unit',
            rules: [{
              required: true,
              message: '请输入单位'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: true,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_giftdetails_add,
        viewBtn: this.permission.bms_giftdetails_view,
        delBtn: this.permission.bms_giftdetails_delete,
        editBtn: this.permission.bms_giftdetails_edit
      }
    }
  },
  methods: {
  }
}
</script>
