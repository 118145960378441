<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>门店员工信息</h2>
    </div>
    <avue-form :option="option" v-model="form"  @submit="handleSubmit">
      <template slot="menuForm">
        <el-button @click="toBranchEmployee">返回</el-button>
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import { submit, getDetail } from '@/api/bms/branchemployee'

export default {
  data () {
    return {
      module: 'bms/branchemployee',
      form: {},
      search: {},
      option: {
        tip: false,
        border: true,
        index: true,
        selection: true,
        labelWidth: 200,
        column: [
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            rules: [{
              required: true,
              message: '请输入所属门店'
            }]
          },
          {
            label: '工号',
            prop: 'username',
            rules: [{
              required: true,
              message: '请输入工号'
            }]
          },
          {
            label: '密码',
            prop: 'password',
            placeholder: '不改密码则不填',
            rules: [{
              required: true,
              message: '请输入密码'
            }]
          },
          {
            label: '姓名',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入姓名'
            }]
          },
          // {
          //   label: '编号',
          //   prop: 'no',
          //   rules: [{
          //     required: true,
          //     message: '请输入编号'
          //   }]
          // },
          {
            label: '手机号',
            prop: 'phone',
            rules: [{
              required: true,
              message: '请输入手机号'
            }]
          },
          {
            label: '身份证号码',
            prop: 'idCard',
            rules: [{
              required: true,
              message: '请输入身份证号码'
            }]
          },
          {
            label: '家庭住址',
            prop: 'address',
            hide: true,
            rules: [{
              message: '请输入家庭住址'
            }]
          },
          {
            label: '紧急联系人',
            prop: 'emergencyContact',
            rules: [{
              message: '请输入紧急联系人'
            }]
          },
          {
            label: '紧急联系电话',
            prop: 'emergencyPhone',
            rules: [{
              message: '请输入紧急联系电话'
            }]
          },
          {
            label: '岗位级别',
            prop: 'level',
            // type: 'select',
            dataType: 'number',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入岗位级别数字'
            }]
            // dicUrl: 'sys/dict/code/branchempolyee_level'
          },
          {
            label: '入职日期',
            prop: 'hireDate',
            hide: true,
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            rules: [{
              message: '请输入入职日期'
            }]
          },
          {
            label: '身份证人头面',
            prop: 'idCardFront',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '身份证背面',
            prop: 'idCardBack',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '健康证',
            prop: 'healthCertificate',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '健康证图片',
            prop: 'healthCertificateImg',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '其他证件照，多个,隔开',
            prop: 'otherLicense',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            span: 24
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              message: '请输入备注'
            }]
          }
        ]
      }
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    handleSubmit (form, done) {
      submit(this.form).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.toBranchEmployee()
        done()
      }).catch(e => {
        console.log(111)
        done()
      })
    },
    toBranchEmployee () {
      this.$router.push({ path: '/bms/branchemployee' })
    }
  }
}
</script>

<style lang="scss">
.el-empty{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
