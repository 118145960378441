<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_member_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row,type,size}" slot="menu">
        <el-button icon="el-icon-switch-button" :size="size" :type="type" @click="frozen(row)">禁用</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { submit } from '@/api/shop/member'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/member',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        column: [
          {
            label: '会员用户名',
            prop: 'username',
            rules: [{
              required: true,
              message: '请输入会员用户名'
            }]
          },
          {
            label: '会员昵称',
            prop: 'nickName'
          },
          {
            label: '手机号码',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入手机号码'
            }]
          },
          {
            label: '积分数量',
            prop: 'point',
            rules: [{
              required: true,
              message: '请输入积分数量'
            }]
          },
          {
            label: '注册时间',
            prop: 'createTime'
          },
          {
            label: '会员性别',
            prop: 'sex',
            hide: true,
            rules: [{
              required: true,
              message: '请输入会员性别'
            }]
          },
          {
            label: '会员生日',
            prop: 'birthday',
            hide: true,
            rules: [{
              required: true,
              message: '请输入会员生日'
            }]
          },
          {
            label: '会员状态',
            prop: 'disabled',
            type: 'select',
            filterable: true,
            dicData: [{
              label: '启用',
              value: true
            },
            {
              label: '禁用',
              value: false
            }]
          },
          {
            label: '是否开通店铺',
            prop: 'haveStore',
            hide: true,
            rules: [{
              required: true,
              message: '请输入是否开通店铺'
            }]
          },
          {
            label: '店铺ID',
            prop: 'storeId',
            hide: true,
            rules: [{
              required: true,
              message: '请输入店铺ID'
            }]
          },
          {
            label: '会员地址',
            prop: 'region',
            hide: true,
            rules: [{
              required: true,
              message: '请输入会员地址'
            }]
          },
          {
            label: '客户端',
            prop: 'clientEnum',
            hide: true,
            rules: [{
              required: true,
              message: '请输入客户端'
            }]
          },
          {
            label: '最后一次登录时间',
            prop: 'lastLoginDate',
            hide: true,
            rules: [{
              required: true,
              message: '请输入最后一次登录时间'
            }]
          },
          {
            label: '积分总数量',
            prop: 'totalPoint',
            hide: true,
            rules: [{
              required: true,
              message: '请输入积分总数量'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_member_add,
        viewBtn: this.permission.shop_member_view,
        delBtn: this.permission.shop_member_delete,
        editBtn: this.permission.shop_member_edit
      }
    }
  },
  methods: {
    frozen (row) {
      this.form = row
      if (this.form.disabled === true) {
        this.form.disabled = false
        submit(this.form).then(() => {
          this.$message({
            type: 'success',
            message: '禁用成功!'
          })
        })
      } else {
        this.$message({ message: '操作失败：已禁用', type: 'error' })
      }
    }
  }
}
</script>
