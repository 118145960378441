import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/vms/beef/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/vms/beef/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/vms/beef/remove',
    method: 'post',
    params: {
      ids
    }
  })
}
export const repeatModel = (str) => {
  const data = new FormData()
  data.append('list', str)
  return request({
    url: '/vms/beef/repeatModel',
    responseType: 'blob',
    method: 'post',
    data
  })
}
export const submit = (row) => {
  return request({
    url: '/vms/beef/submit',
    method: 'post',
    data: row
  })
}

export const getTree = (current, size, params) => {
  return request({
    url: '/vms/beef/tree',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const exportExcel = (params) => {
  return request({
    url: '/vms/beef/export',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}

export const exportSourceExcel = (params) => {
  return request({
    url: '/vms/beef/source/export',
    method: 'get',
    params: params,
    responseType: 'blob'
  })
}
