<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_member_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/member',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '会员生日',
            prop: 'birthday',
            rules: [{
              required: true,
              message: '请输入会员生日'
            }]
          },
          {
            label: '会员状态',
            prop: 'disabled',
            rules: [{
              required: true,
              message: '请输入会员状态'
            }]
          },
          {
            label: '会员头像',
            prop: 'face',
            rules: [{
              required: true,
              message: '请输入会员头像'
            }]
          },
          {
            label: '是否开通店铺',
            prop: 'haveStore',
            rules: [{
              required: true,
              message: '请输入是否开通店铺'
            }]
          },
          {
            label: '手机号码',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入手机号码'
            }]
          },
          {
            label: '会员昵称',
            prop: 'nickName',
            rules: [{
              required: true,
              message: '请输入会员昵称'
            }]
          },
          {
            label: '会员密码',
            prop: 'password',
            rules: [{
              required: true,
              message: '请输入会员密码'
            }]
          },
          {
            label: '积分数量',
            prop: 'point',
            rules: [{
              required: true,
              message: '请输入积分数量'
            }]
          },
          {
            label: '会员性别',
            prop: 'sex',
            rules: [{
              required: true,
              message: '请输入会员性别'
            }]
          },
          {
            label: '店铺ID',
            prop: 'storeId',
            rules: [{
              required: true,
              message: '请输入店铺ID'
            }]
          },
          {
            label: '会员用户名',
            prop: 'username',
            rules: [{
              required: true,
              message: '请输入会员用户名'
            }]
          },
          {
            label: '会员地址',
            prop: 'region',
            rules: [{
              required: true,
              message: '请输入会员地址'
            }]
          },
          {
            label: '会员地址ID',
            prop: 'regionId',
            rules: [{
              required: true,
              message: '请输入会员地址ID'
            }]
          },
          {
            label: '客户端',
            prop: 'clientEnum',
            rules: [{
              required: true,
              message: '请输入客户端'
            }]
          },
          {
            label: '最后一次登录时间',
            prop: 'lastLoginDate',
            rules: [{
              required: true,
              message: '请输入最后一次登录时间'
            }]
          },
          {
            label: '',
            prop: 'gradeid',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '',
            prop: 'experience',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '',
            prop: 'gradeId',
            rules: [{
              required: true,
              message: '请输入'
            }]
          },
          {
            label: '积分总数量',
            prop: 'totalPoint',
            rules: [{
              required: true,
              message: '请输入积分总数量'
            }]
          },
          {
            label: '关联小程序storeId',
            prop: 'fromStoreId',
            rules: [{
              required: true,
              message: '请输入关联小程序storeId'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_member_add,
        viewBtn: this.permission.shop_member_view,
        delBtn: this.permission.shop_member_delete,
        editBtn: this.permission.shop_member_edit
      }
    }
  },
  methods: {
  }
}
</script>
