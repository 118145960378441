<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoadShop">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportOrder()">导出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/common'
import { formatDate } from '@/util/date'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/orderdetailexport',
      form: {},
      search: {},
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: true,
        menu: false,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '订单编号',
            prop: 'sn',
            width: 220
          },
          {
            label: '品名',
            prop: 'goodsMaterialName'
          },
          {
            label: '物料号',
            prop: 'goodsMaterialFullCode'
          },
          {
            label: '商品名称',
            prop: 'goodsName'
          },
          {
            label: 'sku',
            prop: 'simpleSpecs'
          },
          {
            label: '配送时间',
            prop: 'deliveryTime',
            type: 'date',
            searchValue: formatDate(new Date(), 'yyyy-MM-dd'),
            valueFormat: 'yyyy-MM-dd',
            search: true
          },
          {
            label: '所属门店',
            prop: 'branchName',
            search: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        viewBtn: this.permission.shop_orderdetailexport_view
      }
    }
  },
  methods: {
    exportOrder () {
      this.handleExport('订单信息导出')
    },
    onLoadShop (page) {
      this.search.deliveryTime = formatDate(new Date(), 'yyyy-MM-dd')
      getList(this.module, page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    }
  }
}
</script>
