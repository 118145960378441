<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/feedback',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        menu: false,
        addBtn: false,
        editBtn: false,
        viewBtn: false,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '反馈内容',
            prop: 'context',
            rules: [{
              required: true,
              message: '请输入反馈内容'
            }]
          },
          {
            label: '图片',
            prop: 'images',
            dataType: 'string',
            type: 'upload',
            listType: 'picture-card',
            oss: 'ali',
            rules: [{
              required: true,
              message: '请输入图片'
            }]
          },
          {
            label: '手机号',
            prop: 'mobile',
            rules: [{
              required: true,
              message: '请输入手机号'
            }]
          },
          {
            label: '类型',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入类型'
            }]
          },
          {
            label: '会员名称',
            prop: 'userName',
            search: true,
            rules: [{
              required: true,
              message: '请输入会员名称'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_feedback_add,
        viewBtn: this.permission.shop_feedback_view,
        delBtn: this.permission.shop_feedback_delete,
        editBtn: this.permission.shop_feedback_edit
      }
    }
  },
  methods: {
  }
}
</script>
