<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            v-model="form"
            @search-change="searchChangeBranch"
            @search-reset="searchResetBranch"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoadBranchFirst">
      <template slot="menuLeft">
        <el-button
            type="success"
            @click.stop="exportBranch()">导出
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { exportBranch, getBranch } from '@/api/shop/orderdetailexport'
import { formatDate } from '@/util/date'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/orderdetailexport',
      form: {},
      search: {},
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        selection: true,
        menu: false,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '订单编号',
            prop: 'sn',
            width: 220
          },
          {
            label: '品名',
            prop: 'materialName'
          },
          {
            label: '物料号',
            prop: 'materialFullCode'
          },
          {
            label: '重量(公斤)',
            prop: 'weight'
          },
          {
            label: '到店时间',
            prop: 'needDate',
            type: 'date',
            searchValue: formatDate(new Date(), 'yyyy-MM-dd'),
            valueFormat: 'yyyy-MM-dd',
            search: true
          },
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            search: true
          }
        ]
      },
      data: []
    }
  },
  methods: {
    exportBranch () {
      this.branchExport('门店订单信息导出')
    },
    branchExport (name) {
      let fileName = '数据导出' + formatDate(new Date(), 'yyyyMMddhhmmss') + '.xlsx'
      if (name) {
        fileName = name + '.xlsx'
      }
      exportBranch(this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, fileName)
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    onLoadBranchFirst (page) {
      this.search.needDate = formatDate(new Date(), 'yyyy-MM-dd')
      getBranch(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    onLoadBranch (page) {
      getBranch(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    searchChangeBranch (params, done) {
      this.search = params
      this.onLoadBranch(this.page)
      done()
    },
    searchResetBranch () {
      this.search = {}
      this.onLoadBranch(this.page)
    }
  }
}
</script>
