<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_announce_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/announce',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            search: true,
            rules: [{
              required: true,
              message: '请输入门店号'
            }]
          },
          {
            label: '商户号',
            prop: 'merchantNo',
            rules: [{
              required: true,
              message: '请输入商户号'
            }]
          },

          {
            label: '播报器编号',
            prop: 'announcerNo',
            span: 24,
            labelWidth: 120,
            rules: [{
              required: true,
              message: '请输入播报器编号'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_announce_add,
        viewBtn: this.permission.bms_announce_view,
        delBtn: this.permission.bms_announce_delete,
        editBtn: this.permission.bms_announce_edit
      }
    }
  },
  methods: {
  }
}
</script>
