<template>
  <basic-container>
    <avue-crud
        ref="crud"
        v-model="form"
        :before-open="beforeOpen"
        :data="data"
        :option="option"
        :page.sync="page"
        :permission="permissionList"
        :search.sync="search"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            v-if="permission.shop_cookbook_delete"
            icon="el-icon-delete"
            plain
            size="small"
            type="danger"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/cookbook',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        menuWidth: 200,
        dialogWidth: '90%',
        index: true,
        editBtn: false,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '创建时间',
            prop: 'createTime',
            width: 130
          },
          {
            label: '标题',
            prop: 'title',
            width: 90,
            rules: [{
              required: true,
              message: '请输入标题'
            }]
          },
          {
            label: '封面图',
            prop: 'thumbnail',
            dataType: 'string',
            type: 'upload',
            width: 90,
            listType: 'picture-img',
            oss: 'ali',
            span: 24
          },
          {
            label: '难易程度',
            prop: 'level',
            width: 70,
            type: 'select',
            filterable: true,
            dataType: 'string',
            dicUrl: '/sys/dict/code/cookbook_level',
            rules: [{
              required: true,
              message: '请输入难易程度'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            width: 50,
            type: 'select',
            dataType: 'string',
            dicUrl: '/sys/dict/code/cookbook_status',
            rules: [{
              required: true,
              message: '请输入状态 0 草稿 1 发布'
            }]
          },
          {
            label: '内容',
            prop: 'content',
            width: 500,
            component: 'AvueUeditor',
            span: 24,
            rules: [{
              required: true,
              message: '请输入内容'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_cookbook_add,
        viewBtn: this.permission.shop_cookbook_view,
        delBtn: this.permission.shop_cookbook_delete,
        editBtn: this.permission.shop_cookbook_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/shop/cookbookedit',
        query: { id: row.id }
      })
    }
  }
}
</script>
