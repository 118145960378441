<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        v-model="form"
        @tree-load="treeLoad"
        :before-open="beforeOpen"
        @row-del="rowDel"
        @row-update="rowUpdate"
        @row-save="rowSave"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="primary" size="small" @click.stop="handleExport()">导出</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getTree, exportSourceExcel } from '@/api/vms/beef'
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/beef',
      form: {},
      selectionList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        tip: false,
        dialogWidth: '60%',
        tree: true,
        addBtn: false,
        border: true,
        menu: false,
        searchMenuSpan: 5,
        lazy: true,
        rowKey: 'id',
        column: [
          {
            label: '名称',
            prop: 'name'
          },
          {
            label: '编号',
            prop: 'beefCode'
          },
          {
            label: '重量',
            prop: 'inWeight'
          },
          {
            label: '生产日期',
            prop: 'initTime'
          },
          {
            label: '耳标号',
            prop: 'earTag',
            placeholder: '请选择耳标号',
            hide: true,
            search: true,
            type: 'select',
            filterable: true,
            dicUrl: '/vms/cattle/dict'
          }
        ]
      },
      data: []
    }
  },
  methods: {
    treeLoad (tree, treeNode, resolve) {
      getTree(null, null, { parentId: tree.id }).then(res => {
        resolve(res.data)
      })
    },
    onLoad () {
      getTree(this.page.currentPage, this.page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    handleExport () {
      exportSourceExcel(this.search).then(res => {
        const that = this
        if (res.type === 'application/json') {
          const reader = new FileReader()
          reader.readAsText(res, 'utf-8')
          reader.onload = function () {
            res = JSON.parse(reader.result)
            if (!res.success) {
              that.$message({
                type: 'error',
                message: res.msg
              })
            }
          }
          return false
        }
        FileSaver.saveAs(res, '牛肉溯源全流程导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    }
  }
}
</script>

<style>
</style>
