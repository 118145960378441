<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>礼品设置</h2>
    </div>
    <avue-form :option="option" v-model="form" @submit="handleSubmit" :defaults.sync="defaults">
      <template slot="menuForm">
        <el-button @click="toGift">返回</el-button>
      </template>
    </avue-form>
    <avue-crud
        :defaults.sync="detailDefaults"
        :option="detailsOption"
        :data="details"
        :page.sync="detailsPage"
        ref="detailsCrud"
        @row-del="rowDel"
        v-model="detailsForm"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad"
        @row-click="handleRowClick">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            icon="el-icon-add"
            @click="addRow">新增
        </el-button>
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.bms_giftdetails_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
        title="选择物料"
        :visible.sync="showGift"
        width="60%">
      <div class="selectAll" style="margin-bottom: 10px;">
        <div
:class="'checkbox ' + (isSelectedAll?'active':'inactive')"
            style="margin-right: 10px;"
             @click="switchSelectAll"/>
        <div>全选</div>
      </div>
      <el-row :gutter="20">
        <el-checkbox-group>
          <el-col :span="8" v-for="(item, index) in materials" :key="index">
            <div class="item">
              <div
:id="item.id"
:class="'checkbox ' + (item.isActive?'active':'inactive')"
style="margin-right: 10px;"
                   @click="materialChanged"></div>
              <div class="cover">
                <img :src="item.logo?item.logo:materialImg"/>
              </div>
              <div class="info">
                <p>
                  {{ item.name }}
                </p>
                <p>{{ item.price }}</p>
              </div>
            </div>
          </el-col>
        </el-checkbox-group>
      </el-row>
      <div class="block">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="materialPage.currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            layout="sizes, prev, pager, next"
            :total="materialPage.total">
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGift = false">取 消</el-button>
        <el-button type="primary" @click="selectMaterials">确 定</el-button>
      </span>
    </el-dialog>
  </basic-container>
</template>
<script>
import mixin from '@/util/mixin'
import { deepClone } from '@/util'
import { getDetail } from '@/api/bms/gift'
import materialImg from '../../assets/img/material.png'
import { submit } from '@/api/common'
import { getListInActivity } from '@/api/vms/material'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/gift',
      form: {},
      detailsForm: {},
      materialImg: materialImg,
      search: {},
      materialSearch: {
        type: 2,
        level: 4
      },
      defaults: {},
      detailDefaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      detailsPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      materialPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectedGoods: '', // 已选商品列表，便于删除
      realSelectedGoods: [],
      sign: 'goods',
      selectionList: [],
      isSelectedAll: false,
      showGift: false,
      materials: [],
      materialsInActivity: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '获取门槛',
            prop: 'step',
            type: 'number',
            size: 'mini',
            precision: 2,
            min: 0,
            value: 1,
            placeholder: '请设置赠品获取金额门槛',
            rules: [{
              required: true,
              message: '请设置赠品获取金额门槛'
            }]
          },
          {
            label: '赠送数量',
            prop: 'singleMulti',
            type: 'radio',
            dicData: [{ value: 0, label: '全部' }, { value: 1, label: '部分' }],
            value: 0,
            span: 12,
            rules: [{
              required: true,
              message: '请设置赠送数量'
            }]
          },
          {
            label: '',
            prop: 'singleMultiNum',
            placeholder: '请设置赠送数量',
            type: 'number',
            value: 1,
            span: 6,
            display: false,
            step: 1,
            min: 0
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ]
      },
      detailsOption: {
        align: 'center',
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        cellBtn: true,
        addRowBtn: false,
        selection: true,
        column: [
          {
            label: '赠品类型',
            prop: 'type',
            type: 'radio',
            value: 0,
            width: 180,
            dicData: [{ value: 0, label: '实物' }, { value: 1, label: '物料' }],
            cell: true,
            rules: [{
              required: true,
              message: '请设置赠品类型'
            }],
            change: ({ row, value }) => {
              this.activity = row
              this.$refs.detailsCrud.data.forEach((item, index) => {
                if (index === row.$index) {
                  item.$cellEdit = true
                } else {
                  item.$cellEdit = false
                }
              })
            }
          },
          {
            label: '礼品',
            prop: 'name',
            placeholder: '请输入礼品名称',
            cell: true,
            width: 180,
            rules: [{
              required: true,
              message: '请输入礼品名称'
            }]
          },
          {
            label: '物料',
            prop: 'materialIds',
            cell: true,
            type: 'select',
            multiple: true,
            width: 180,
            dicUrl: '/vms/beef/material',
            placeholder: '请选择物料',
            rules: [{
              required: true,
              message: '请选择物料'
            }],
            focus: () => {
              this.showGift = true
              this.listMaterials()
            }
            // change: ({ row, value }) => {
            //   console.log('change', row)
            //   this.activity = row
            //   this.$refs.detailsCrud.data.forEach((item, index) => {
            //     if (index === row.$index) {
            //       item.$cellEdit = true
            //     } else {
            //       item.$cellEdit = false
            //     }
            //   })
            // }
          },
          {
            label: '数量',
            prop: 'num',
            placeholder: '1',
            type: 'number',
            min: 0,
            cell: true,
            width: 120,
            rules: [{
              required: true,
              message: '请设置赠品数量'
            }]
          },
          {
            label: '单位',
            prop: 'unit',
            cell: true,
            placeholder: '个...',
            width: 100,
            rules: [{
              required: true,
              message: '请设置单位'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            cell: true,
            width: 150,
            placeholder: '请设置备注'
          }
        ]
      },
      data: [],
      details: [],
      activity: {},
      columnKey: 'type',
      currentIndex: 0 // 选中的下标
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_gift_add,
        viewBtn: this.permission.bms_gift_view,
        delBtn: this.permission.bms_gift_delete,
        editBtn: this.permission.bms_gift_edit
      }
    }
  },
  watch: {
    'form.singleMulti': {
      handler: function (val) {
        // 非全选时，隐藏数量
        if (!val) {
          this.defaults.singleMulti.span = 12
          this.defaults.singleMultiNum.display = false
        } else {
          this.defaults.singleMulti.span = 6
          this.defaults.singleMultiNum.display = true
        }
      },
      deep: true
    },
    details: {
      handler: function (val) {
        this.defaults.singleMultiNum.max = val.length - 1
        if (val.length) {
          this.defaults.singleMulti.dicData = [{ value: 0, label: '全部' }, { value: 1, label: '部分' }]
          if (this.columnKey === 'type') {
            const detail = val[this.currentIndex]
            if (detail.type === 1) {
              this.detailDefaults.unit.cell = false
              this.detailDefaults.materialIds.cell = true
              this.detailDefaults.name.cell = false
            } else {
              this.detailDefaults.materialIds.cell = false
              this.detailDefaults.name.cell = true
            }
          }
        } else {
          this.defaults.singleMulti.dicData = [{ value: 0, label: '全部' }]
        }
      },
      deep: true,
      immediate: false
    }
  },
  methods: {
    loadData () {
      getDetail(this.form.id).then(res => {
        this.form = res.data
        this.form.singleMultiNum = this.form.singleMulti
        this.form.singleMulti = this.form.singleMulti >= 1 ? 1 : 0
        this.details = this.form.details
        if (this.details.length) {
          this.defaults.singleMulti.dicData = [{ value: 0, label: '全部' }, { value: 1, label: '部分' }]
        } else {
          this.defaults.singleMulti.dicData = [{ value: 0, label: '全部' }]
        }
      })
    },
    listMaterials () {
      this.materialSearch.value = this.form.step
      getListInActivity(this.materialPage.currentPage, this.materialPage.pageSize, this.materialSearch).then(res => {
        if (res.success && res.data.records && res.data.records.length) {
          this.materialSearch.value = null
          this.materials = res.data.records
          if (this.materialsInActivity.length) {
            this.materials.forEach(item => {
              if (this.materialsInActivity.indexOf(item.id) >= 0) {
                item.isActive = true
              }
            })
          }
          this.materialsInActivity = this.materials.filter(item => item.isActive).map(item => item.id)
          this.materialPage.total = res.data.total
        } else {
          this.$message.error(res.msg ? res.msg : '获取物料列表失败！')
        }
      })
    },
    toGift () {
      this.$router.push({ path: '/bms/gift' })
    },
    // cell方式增加数据
    addRow () {
      let _search = {}
      if (this.details.length > 0) {
        _search = deepClone(this.details[this.details.length - 1])
        _search.name = null
        _search.unit = null
        _search.num = null
        _search.materialIds = null
        _search.remark = null
      }
      _search.type = 0
      _search.$cellEdit = true
      this.details.splice(0, 0, _search)
      this.detailDefaults.unit.cell = true
    },
    handleRowClick (row, event, column) {
      this.$refs.detailsCrud.data.forEach((item, index) => {
        if (index === row.$index) {
          item.$cellEdit = true
        } else {
          item.$cellEdit = false
        }
      })
      this.currentIndex = row.$index
      if (event) {
        this.columnKey = event.columnKey
      }
    },
    // 全选
    switchSelectAll () {
      this.isSelectedAll = !this.isSelectedAll
      this.materials.forEach(item => {
        item.isActive = this.isSelectedAll
        return item
      })
      if (this.isSelectedAll) {
        this.materialsInActivity = this.materials.map(item => item.id)
      } else {
        this.materialsInActivity = []
      }
    },
    // 物料窗口点确定
    selectMaterials: function () {
      if (this.materialsInActivity.length) {
        this.showGift = false
        const data = this.$refs.detailsCrud.data.filter(item => item.$cellEdit)[0]
        console.log('before', data)
        this.materials.forEach(item => {
          if (this.materialsInActivity.indexOf(item.id) >= 0) {
            item.isActive = true
          } else {
            item.isActive = false
          }
        })
        data.materialIds = this.materials.filter(item => item.isActive).map(item => item.id).join(',')
        // todo fix不触发物料下拉框的change事件
        this.rowSave(data, () => { data.$cellEdit = false })
        console.log('after', data)
      } else {
        this.$message.error('请至少选择一个物料！')
      }
    },
    // 复选框单击，设置物料isActive，且活动物料数组中插入或移出
    materialChanged (e) {
      const className = e.target.className.split(' ')
      const isActive = className[1] === 'active'
      e.target.className = className[0] + ' ' + (isActive ? 'inactive' : 'active')
      const id = e.target.id
      this.materials.forEach(item => {
        if (item.id === id) {
          item.isActive = !isActive
        }
      })
      this.materialsInActivity = this.materials.filter(item => item.isActive).map(item => item.id)
    },
    handleSizeChange (val) {
      this.materialPage.pageSize = val
      this.listMaterials()
    },
    handleCurrentChange (val) {
      this.materialPage.currentPage = val
      this.listMaterials()
    },
    handleSubmit: function (row, done, loading) {
      if (!this.details || !this.details.length) {
        this.$message.error('请设置礼品！')
        done()
        return
      }
      console.log(row)
      row.details = this.details
      submit(this.module, row).then((res) => {
        done()
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.toGift()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      }).catch(() => {
        loading()
      })
    },
    rowSave (row, done) {
      console.log('rowSave')
      if (row.materialIds && row.materialIds.split(',').length > 1) {
        const materialIds = row.materialIds.split(',')
        let oldMaterialIds = []
        if (this.details && this.details.length) {
          oldMaterialIds = this.details.map(item => item.materialId)
        }
        if (!row.num) {
          row.num = 1
        }
        // 过滤掉已加的物料，将剩余的物料插入details
        this.materials.forEach((item) => {
          const materialId = item.id
          if (materialIds.indexOf(materialId) >= 0 && oldMaterialIds.indexOf(materialId) < 0) {
            const detail = deepClone(row)
            detail.materialIds = item.id
            detail.name = item.name
            detail.unit = '千克'
            this.details.push(detail)
          }
        })
        this.details.splice(this.currentIndex, 1)
      }
      done()
    },
    rowDel (row, index) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(index)
        this.details.splice(index, 1)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;

  .cover {
    display: flex;
    align-items: center;
    width: 60px;
    height: 100%;
    margin-right: 6px;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    height: 100%;
    font-size: 14px;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      &:nth-child(2n) {
        margin-top: 8px;
      }
    }
  }

  .checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background: #409EFF;
    border-radius: 50%;
  }
}
.checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 14px;
  height: 14px;
}
.active {
  background: url("../../assets/img/selected.png") no-repeat right;
  background-size: 100%;
}
.inactive {
  border-radius: 50%;
  border: 1px solid #7b7b7b;
  background: #fafafa;
}
</style>
