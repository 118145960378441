<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template
          slot-scope="{row}"
          slot="icon">
        <div style="text-align:center">
          <i :class="row.icon"></i>
        </div>
      </template>
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getList } from '@/api/shop/articlecategory'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/articlecategory',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        tree: true,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '分类名称',
            prop: 'articleCategoryName',
            rules: [{
              required: true,
              message: '请输入分类名称'
            }]
          },
          {
            label: '分类类型',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入分类类型'
            }]
          },
          {
            label: '排序',
            prop: 'sort'
          },
          {
            label: '上级菜单',
            prop: 'parentId',
            type: 'tree',
            dicUrl: '/shop/articlecategory/articleTree',
            hide: true,
            props: {
              value: 'id',
              label: 'articleCategoryName'
            }
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_articlecategory_add,
        viewBtn: this.permission.shop_articlecategory_view,
        delBtn: this.permission.shop_articlecategory_delete,
        editBtn: this.permission.shop_articlecategory_edit
      }
    }
  },
  methods: {
    onLoad (page, params = {}, callback) {
      getList(page.currentPage, page.pageSize, params).then(res => {
        const data = res.data
        this.data = data
        if (callback) {
          callback()
        }
      })
    }
  }
}
</script>
