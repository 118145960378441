<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_seckill_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/seckill',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: 'ID',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入ID'
            }]
          },
          {
            label: '创建者',
            prop: 'createBy',
            rules: [{
              required: true,
              message: '请输入创建者'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '删除标志 true/false 删除/未删除',
            prop: 'deleteFlag',
            rules: [{
              required: true,
              message: '请输入删除标志 true/false 删除/未删除'
            }]
          },
          {
            label: '更新者',
            prop: 'updateBy',
            rules: [{
              required: true,
              message: '请输入更新者'
            }]
          },
          {
            label: '更新时间',
            prop: 'updateTime',
            rules: [{
              required: true,
              message: '请输入更新时间'
            }]
          },
          {
            label: '活动结束时间',
            prop: 'endTime',
            rules: [{
              required: true,
              message: '请输入活动结束时间'
            }]
          },
          {
            label: '活动名称',
            prop: 'promotionName',
            rules: [{
              required: true,
              message: '请输入活动名称'
            }]
          },
          {
            label: '范围关联的ID',
            prop: 'scopeId',
            rules: [{
              required: true,
              message: '请输入范围关联的ID'
            }]
          },
          {
            label: '关联范围类型',
            prop: 'scopeType',
            rules: [{
              required: true,
              message: '请输入关联范围类型'
            }]
          },
          {
            label: '店铺ID',
            prop: 'storeId',
            rules: [{
              required: true,
              message: '请输入店铺ID'
            }]
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          },
          {
            label: '活动开始时间',
            prop: 'startTime',
            rules: [{
              required: true,
              message: '请输入活动开始时间'
            }]
          },
          {
            label: '报名截至时间',
            prop: 'applyEndTime',
            rules: [{
              required: true,
              message: '请输入报名截至时间'
            }]
          },
          {
            label: '开启几点场',
            prop: 'hours',
            rules: [{
              required: true,
              message: '请输入开启几点场'
            }]
          },
          {
            label: '申请规则',
            prop: 'seckillRule',
            rules: [{
              required: true,
              message: '请输入申请规则'
            }]
          },
          {
            label: '店铺ID集合以逗号分隔',
            prop: 'storeIds',
            rules: [{
              required: true,
              message: '请输入店铺ID集合以逗号分隔'
            }]
          },
          {
            label: '',
            prop: 'goodsNum',
            rules: [{
              required: true,
              message: '请输入'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_seckill_add,
        viewBtn: this.permission.shop_seckill_view,
        delBtn: this.permission.shop_seckill_delete,
        editBtn: this.permission.shop_seckill_edit
      }
    }
  },
  methods: {
  }
}
</script>
