<template>
  <div>
    <basic-container>
      123
    </basic-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created () {
  },
  methods: {}
}
</script>

<style scoped="scoped" lang="scss">
</style>
