<template>
  <basic-container>
    <div class="filter-container space-between">
      <div class="search-container start">
        <el-input
            @keyup.enter.native="handleFilter"
            class="filter-item"
            placeholder="类型"
            v-model="page.type"></el-input>
        <el-button class="filter-item" type="primary" icon="search" @click="handleFilter">搜索</el-button>
        <el-button class="filter-item" @click="handleCreate" type="primary" icon="edit">
          添加
        </el-button>
      </div>
      <div>
      </div>
    </div>
    <el-table
        :data="list"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        highlight-current-row>
      <el-table-column label="主键" prop="id">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号名称" prop="name">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号appid" prop="appid">
        <template slot-scope="scope">
          <span>{{ scope.row.appid }}</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号密钥" prop="appSecret">
        <template slot-scope="scope">
          <span>{{ scope.row.appSecret }}</span>
        </template>
      </el-table-column>
      <el-table-column label="token" prop="token">
        <template slot-scope="scope">
          <span>{{ scope.row.token }}</span>
        </template>
      </el-table-column>
      <el-table-column label="aes_key" prop="aesKey">
        <template slot-scope="scope">
          <span>{{ scope.row.aesKey }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型 1小程序 2 公众号 3 开放平台" prop="type">
        <template slot-scope="scope">
          <span>{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <el-table-column label="加入开放平台" prop="open">
        <template slot-scope="scope">
          <span>{{ scope.row.open }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
          <el-button
              v-if="scope.row.status!='deleted'"
              size="small"
              type="danger"
              @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
          :current-page.sync="page.currentPage"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-sizes="[10,20,30, 50]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog title="小程序账户表" :visible.sync="showDialog">
      <el-row :gutter="20">
        <el-form
            :model="form"
            ref="form"
            :rules="rules"
            label-position="left"
            label-width="120px"
            class="small-space"
            style='padding:20px 60px;'>
          <el-col :span="12">
            <el-form-item label="主键：" prop="id">
              <el-input v-model="form.id" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公众号名称：" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公众号appid：" prop="appid">
              <el-input v-model="form.appid"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公众号密钥：" prop="appSecret">
              <el-input v-model="form.appSecret"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="token：" prop="token">
              <el-input v-model="form.token"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="aes_key：" prop="aesKey">
              <el-input v-model="form.aesKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型 1小程序 2 公众号 3 开放平台：" prop="type">
              <el-input v-model="form.type"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="加入开放平台：" prop="open">
              <el-input v-model="form.open"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import { getDetail, getList, remove, submit } from '@/api/ma/test'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        name: undefined,
        appid: undefined,
        appSecret: undefined,
        token: undefined,
        aesKey: undefined,
        type: undefined,
        open: undefined
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请输入 公众号名称' }],
        appid: [{ required: true, trigger: 'blur', message: '请输入 公众号appid' }],
        appSecret: [{ required: true, trigger: 'blur', message: '请输入 公众号密钥' }],
        token: [{ required: true, trigger: 'blur', message: '请输入 token' }],
        aesKey: [{ required: true, trigger: 'blur', message: '请输入 aes_key' }],
        type: [{ required: true, trigger: 'blur', message: '请输入 类型 1小程序 2 公众号 3 开放平台' }],
        open: [{ required: true, trigger: 'blur', message: '请输入 加入开放平台' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }
}
</script>
