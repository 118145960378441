<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
      <template slot-scope="{row,index}" slot="menu">
        <el-button icon="el-icon-edit" type="text"  :index="index" @click="handleEdit(row)">编辑</el-button>
        <el-button icon="el-icon-sort" type="text" v-if="row.marketEnable === 'UPPER' " :index="index"   @click="up(row)">上架</el-button>
        <el-button icon="el-icon-sort" type="text" v-if="row.marketEnable === 'DOWN'" :index="index" @click="down(row)">下架</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { updateMarketEnable } from '@/api/shop/goods'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/goods',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        delBtn: false,
        addBtn: false,
        editBtn: false,
        menuWidth: 120,
        selection: true,
        searchMenuSpan: 21,
        column: [
          {
            label: '商品名称',
            prop: 'goodsName',
            search: true
          },
          {
            label: '图片',
            prop: 'original',
            dataType: 'string',
            type: 'upload',
            listType: 'picture-card',
            oss: 'ali'
          },
          {
            label: '商品编号',
            prop: 'id',
            search: true
          },
          {
            label: '成本价格',
            prop: 'cost',
            hide: true
          },
          {
            label: '计量单位',
            prop: 'goodsUnit',
            hide: true
          },
          {
            label: '上架状态',
            prop: 'marketEnable',
            type: 'select',
            search: true,
            dicData: [{
              label: '上架',
              value: 'UPPER'
            },
            {
              label: '下架',
              value: 'DOWN'
            }]
          },
          {
            label: '审核状态',
            prop: 'authFlag',
            type: 'select',
            dicData: [{
              label: '通过',
              value: 'PASS'
            }]
          },
          {
            label: '商品移动端详情',
            prop: 'mobileIntro',
            hide: true
          },
          {
            label: '商品价格',
            prop: 'price'
          },
          {
            label: '商品类型',
            prop: 'goodsType',
            type: 'select',
            filterable: true,
            search: true,
            dicData: [{
              label: '实物商品',
              value: 'PHYSICAL_GOODS'
            },
            {
              label: '虚拟商品',
              value: 'VIRTUAL_GOODS'
            },
            {
              label: '电子卡券',
              value: 'E_COUPON'
            }]
          },
          {
            label: '库存',
            prop: 'quantity'
          },
          {
            label: '是否为推荐商品',
            prop: 'recommend',
            type: 'select',
            dicData: [{
              label: '是',
              value: true
            },
            {
              label: '否',
              value: false
            }],
            hide: true
          },
          {
            label: '销售模式',
            prop: 'salesModel',
            hide: true
          },
          {
            label: '店铺名称',
            prop: 'storeName'
          },
          {
            label: '卖点',
            prop: 'sellingPoint',
            hide: true
          },
          {
            label: '店铺分类',
            prop: 'shopCategoryPath',
            hide: true
          },
          {
            label: '运费模板ID',
            prop: 'templateId',
            hide: true
          },
          {
            label: '下架原因',
            prop: 'underMessage',
            hide: true
          },
          {
            label: '重量',
            prop: 'weight',
            hide: true
          },
          {
            label: '店铺分类路径',
            prop: 'storeCategoryPath',
            hide: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_goods_add,
        viewBtn: this.permission.shop_goods_view,
        delBtn: this.permission.shop_goods_delete,
        editBtn: this.permission.shop_goods_edit
      }
    }
  },
  methods: {
    up (row) {
      row.marketEnable = 'UPPER'
      console.log(row)
      updateMarketEnable(row)
    },
    down (row) {
      row.marketEnable = 'DOWN'
      console.log(row)
      updateMarketEnable(row)
    },
    handleEdit (row) {
      this.$router.push({
        path: '/shop/goodsrelease',
        query: { id: row.id }
      })
    }
  }
}
</script>
