<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_branchscaleconfig_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchscaleconfig',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        cellBtn: true,
        addRowBtn: true,
        selection: true,
        column: [
          {
            label: '抹零单位',
            prop: 'resetUnit',
            cell: true,
            type: 'select',
            dicData: [{ value: 0, label: '不抹零' }, { value: 1, label: '元' }, { value: 2, label: '角' }, { value: 3, label: '分' }],
            rules: [{
              required: true,
              message: '请设置抹零单位'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            cell: true
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchscaleconfig_add,
        viewBtn: this.permission.bms_branchscaleconfig_view,
        delBtn: this.permission.bms_branchscaleconfig_delete,
        editBtn: this.permission.bms_branchscaleconfig_edit,
        addRowBtn: !this.data.length
      }
    }
  },
  methods: {
    // cell方式增加数据
    addRow () {
      this.data.splice(0, 0, {
        $cellEdit: true
      })
    }
  }
}
</script>
