<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.shop_memberevaluation_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/memberevaluation',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '创建者',
            prop: 'createBy',
            rules: [{
              required: true,
              message: '请输入创建者'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '评价内容',
            prop: 'content',
            rules: [{
              required: true,
              message: '请输入评价内容'
            }]
          },
          {
            label: '商品ID',
            prop: 'goodsId',
            rules: [{
              required: true,
              message: '请输入商品ID'
            }]
          },
          {
            label: '商品图片',
            prop: 'goodsImage',
            rules: [{
              required: true,
              message: '请输入商品图片'
            }]
          },
          {
            label: '商品名称',
            prop: 'goodsName',
            rules: [{
              required: true,
              message: '请输入商品名称'
            }]
          },
          {
            label: '好中差评',
            prop: 'grade',
            rules: [{
              required: true,
              message: '请输入好中差评'
            }]
          },
          {
            label: '评价是否有图片',
            prop: 'haveImage',
            rules: [{
              required: true,
              message: '请输入评价是否有图片'
            }]
          },
          {
            label: '回复是否有图片',
            prop: 'haveReplyImage',
            rules: [{
              required: true,
              message: '请输入回复是否有图片'
            }]
          },
          {
            label: '评价图片',
            prop: 'images',
            rules: [{
              required: true,
              message: '请输入评价图片'
            }]
          },
          {
            label: '会员ID',
            prop: 'memberId',
            rules: [{
              required: true,
              message: '请输入会员ID'
            }]
          },
          {
            label: '会员名称',
            prop: 'memberName',
            rules: [{
              required: true,
              message: '请输入会员名称'
            }]
          },
          {
            label: '会员头像',
            prop: 'memberProfile',
            rules: [{
              required: true,
              message: '请输入会员头像'
            }]
          },
          {
            label: '订单号',
            prop: 'orderNo',
            rules: [{
              required: true,
              message: '请输入订单号'
            }]
          },
          {
            label: '评价回复',
            prop: 'reply',
            rules: [{
              required: true,
              message: '请输入评价回复'
            }]
          },
          {
            label: '评价回复图片',
            prop: 'replyImage',
            rules: [{
              required: true,
              message: '请输入评价回复图片'
            }]
          },
          {
            label: '店铺ID',
            prop: 'storeId',
            rules: [{
              required: true,
              message: '请输入店铺ID'
            }]
          },
          {
            label: '货品ID',
            prop: 'skuId',
            rules: [{
              required: true,
              message: '请输入货品ID'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入状态'
            }]
          },
          {
            label: '回复状态',
            prop: 'replyStatus',
            rules: [{
              required: true,
              message: '请输入回复状态'
            }]
          },
          {
            label: '物流评分',
            prop: 'deliveryScore',
            rules: [{
              required: true,
              message: '请输入物流评分'
            }]
          },
          {
            label: '描述评分',
            prop: 'descriptionScore',
            rules: [{
              required: true,
              message: '请输入描述评分'
            }]
          },
          {
            label: '服务评分',
            prop: 'serviceScore',
            rules: [{
              required: true,
              message: '请输入服务评分'
            }]
          },
          {
            label: '店铺名称',
            prop: 'storeName',
            rules: [{
              required: true,
              message: '请输入店铺名称'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_memberevaluation_add,
        viewBtn: this.permission.shop_memberevaluation_view,
        delBtn: this.permission.shop_memberevaluation_delete,
        editBtn: this.permission.shop_memberevaluation_edit
      }
    }
  },
  methods: {
  }
}
</script>
