<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/materialbom',
      form: {},
      search: {},
      page: {
        pageSize: 30,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        addBtn: false,
        cellBtn: true,
        selection: true,
        searchMenuSpan: 5,
        indexLabel: '序号',
        column: [
          {
            label: '品名',
            prop: 'id',
            search: true,
            cell: true,
            type: 'select',
            filterable: true,
            dicUrl: '/vms/materialbom/material',
            rules: [{
              required: true,
              message: '请输入参考重量（公斤）'
            }]
          },
          {
            label: '物料编号',
            prop: 'fullCode',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '参考重量（公斤）',
            prop: 'weight',
            cell: true,
            rules: [{
              required: true,
              message: '请输入参考重量（公斤）'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_materialbom_add,
        viewBtn: this.permission.vms_materialbom_view,
        delBtn: this.permission.vms_materialbom_delete,
        editBtn: this.permission.vms_materialbom_edit
      }
    }
  },
  methods: {
  }
}
</script>
