import { request } from '@/util/http'

export const getErrorList = (current, size) => {
  return request({
    url: '/log/error/list',
    method: 'get',
    params: {
      current,
      size
    }
  })
}

export const getErrorLogs = (id) => {
  return request({
    url: '/log/error/detail',
    method: 'get',
    params: {
      id
    }
  })
}

