<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.vms_cattleslaughter_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-plus"
            size="small"
            @click.stop="toCattleTestResult(row)">添加检测信息
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/cattleslaughter',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        labelWidth: 120,
        tip: false,
        border: true,
        index: true,
        indexLabel: '序号',
        viewBtn: true,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '活牛耳标号',
            prop: 'cattleId',
            type: 'select',
            dicUrl: 'vms/cattle/earTagDict',
            search: true,
            searchLabelWidth: 120,
            filterable: true,
            rules: [{
              message: '请选择活牛耳标号',
              required: true
            }]
          },
          {
            label: '屠宰地',
            prop: 'slaughterHouse',
            maxlength: 10,
            showWordLimit: true,
            rules: [{
              message: '请输入屠宰地',
              required: true
            }]
          },
          {
            label: '屠宰场名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入屠宰场名称'
            }]
          },
          {
            label: '屠宰日期',
            prop: 'slaughterDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            editDisabled: true,
            rules: [{
              message: '请选择屠宰日期',
              required: true
            }]
          },
          {
            label: '屠宰标准',
            prop: 'slaughterStandard',
            rules: [{
              message: '请选择屠宰标准',
              required: true
            }]
          },
          {
            label: '检测日期',
            prop: 'inspectionDate',
            type: 'date',
            valueFormat: 'yyyy-MM-dd',
            editDisabled: true,
            rules: [{
              message: '请选择检测日期',
              required: true
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.option.addBtn = this.permissionList.addBtn
    this.option.viewBtn = this.permissionList.viewBtn
    this.option.delBtn = this.permissionList.delBtn
    this.option.editBtn = this.permissionList.editBtn
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_cattleslaughter_add,
        viewBtn: this.permission.vms_cattleslaughter_view,
        delBtn: this.permission.vms_cattleslaughter_delete,
        editBtn: this.permission.vms_cattleslaughter_edit
      }
    }
  },
  methods: {
    toCattleTestResult (row) {
      this.$router.push({
        path: '/vms/cattletestresult',
        query: { cattleId: row.cattleId }
      })
    }
  }
}
</script>
