import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/shop/coupon/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/shop/coupon/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/shop/coupon/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/shop/coupon/submit',
    method: 'post',
    data: row
  })
}

export const skuInfo = (value) => {
  return request({
    url: '/shop/coupon/getSkuInfo',
    method: 'get',
    params: {
      value
    }
  })
}

