<template>
  <basic-container>
    <avue-crud
            :option="option"
            :defaults.sync="defaults"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { skuInfo } from '@/api/shop/coupon'

export default {
  mixins: [mixin],
  data: function () {
    return {
      module: 'shop/coupon',
      defaults: {},
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        dialogWidth: '90%',
        selection: true,
        menuWidth: 110,
        searchMenuSpan: 5,
        column: [
          {
            label: '活动名称',
            prop: 'promotionName',
            rules: [{
              required: true,
              message: '请输入活动名称'
            }]
          },
          {
            label: '优惠券名称',
            prop: 'couponName',
            search: true,
            searchLabelWidth: 90,
            rules: [{
              required: true,
              message: '请输入优惠券名称'
            }]
          },
          {
            label: '活动开始时间',
            prop: 'startTime',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入活动开始时间'
            }]
          },
          {
            label: '活动结束时间',
            prop: 'endTime',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入活动结束时间'
            }]
          },
          {
            label: '活动类型',
            prop: 'couponType',
            type: 'radio',
            value: 'PRICE',
            span: 24,
            dicUrl: '/sys/dict/code/coupon_activity_type',
            rules: [{
              required: true,
              message: '请输入活动类型'
            }]
          },
          {
            label: '折扣',
            prop: 'couponDiscount',
            type: 'number',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入折扣'
            }]
          },
          {
            label: '面额',
            prop: 'price',
            type: 'number',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入面额'
            }]
          },
          {
            label: '优惠券获取途径',
            prop: 'getType',
            type: 'radio',
            value: 'FREE',
            span: 24,
            dicUrl: '/sys/dict/code/coupon_type',
            rules: [{
              required: true,
              message: '请输入优惠券类型'
            }]
          },
          {
            label: '发行数量',
            prop: 'publishNum',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入发行数量'
            }]
          },
          {
            label: '领取限制',
            prop: 'couponLimitNum',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入领取限制'
            }]
          },
          {
            label: '优惠券类型',
            prop: 'showSign',
            type: 'radio',
            value: 0,
            dicData: [{
              label: '普通券',
              value: 0
            }, {
              label: '新人券',
              value: 1
            }, {
              label: '地推券',
              value: 2
            }],
            rules: [{
              required: true,
              message: '请输入发行数量'
            }]
          },
          {
            label: '消费门槛',
            prop: 'consumeThreshold',
            rules: [{
              required: true,
              message: '请输入消费门槛'
            }]
          },
          {
            label: '已被领取的数量',
            prop: 'receivedNum',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入已被领取的数量'
            }]
          },
          {
            label: '已被使用的数量',
            prop: 'usedNum',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入已被使用的数量'
            }]
          },
          {
            label: '领取开始时间',
            prop: 'receiveStartTime',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入已被使用的数量'
            }]
          },
          {
            label: '领取结束时间',
            prop: 'receiveEndTime',
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请输入已被使用的数量'
            }]
          },
          {
            label: '品类描述',
            prop: 'scopeType',
            type: 'radio',
            value: 'ALL',
            span: 24,
            dicUrl: '/sys/dict/code/coupon_scope_type',
            rules: [{
              required: true,
              message: '请输入品类描述'
            }]
          },
          {
            label: '关联商品',
            prop: 'promotionGoodsList',
            type: 'dynamic',
            hide: true,
            addDisplay: false,
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              column: [
                {
                  label: '商品名称',
                  prop: 'skuId',
                  addDisplay: false,
                  type: 'select',
                  filterable: true,
                  dicUrl: '/shop/goods/goodsList',
                  change: ({ value }) => {
                    skuInfo(value).then(res => {
                      this.form.promotionGoodsList.forEach(item => {
                        if (item.skuId === res.data[0].skuId) {
                          item.quantity = res.data[0].quantity
                          item.originalPrice = res.data[0].originalPrice
                          item.goodsName = res.data[0].goodsName
                          item.goodsId = res.data[0].goodsId
                          item.price = res.data[0].originalPrice
                        }
                      })
                    })
                  },
                  rules: [{
                    required: true,
                    message: '请输入关联商品'
                  }]
                },
                {
                  label: '单价',
                  prop: 'originalPrice',
                  readonly: true
                },
                {
                  label: '库存',
                  prop: 'quantity',
                  readonly: true
                },
                {
                  label: '商品名称',
                  prop: 'goodsName',
                  hide: true
                },
                {
                  label: '商品id',
                  prop: 'goodsId',
                  hide: true
                },
                {
                  label: '价格',
                  prop: 'price',
                  hide: true
                }
              ]
            }
          },
          {
            label: '活动描述',
            prop: 'description',
            span: 24,
            rules: [{
              required: true,
              message: '请输入活动描述'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_coupon_add,
        viewBtn: this.permission.shop_coupon_view,
        delBtn: this.permission.shop_coupon_delete,
        editBtn: this.permission.shop_coupon_edit
      }
    }
  },
  watch: {
    'form.couponType' (val) {
      if (val === 'DISCOUNT') {
        this.defaults.couponDiscount.addDisplay = true
        this.defaults.price.addDisplay = false
        this.defaults.couponDiscount.viewDisplay = true
        this.defaults.price.viewDisplay = false
      } else {
        this.defaults.couponDiscount.addDisplay = false
        this.defaults.price.addDisplay = true
        this.defaults.couponDiscount.viewDisplay = false
        this.defaults.price.viewDisplay = true
      }
    },
    'form.getType' (val) {
      if (val === 'FREE') {
        this.defaults.publishNum.addDisplay = true
        this.defaults.couponLimitNum.addDisplay = true
        this.defaults.publishNum.viewDisplay = true
        this.defaults.couponLimitNum.viewDisplay = true
      } else {
        this.defaults.publishNum.addDisplay = false
        this.defaults.couponLimitNum.addDisplay = false
        this.defaults.publishNum.viewDisplay = false
        this.defaults.couponLimitNum.viewDisplay = false
      }
    },
    'form.scopeType' (val) {
      if (val === 'PORTION_GOODS') {
        this.defaults.promotionGoodsList.addDisplay = true
        this.defaults.promotionGoodsList.viewDisplay = true
      } else {
        this.defaults.promotionGoodsList.addDisplay = false
        this.defaults.promotionGoodsList.viewDisplay = false
      }
    }
  },
  methods: {
  }
}
</script>
