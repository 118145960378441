<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="primary"
            size="small"
            @click.stop="toAdd()">新增
        </el-button>
        <el-button
            type="success"
            @click.stop="exportEmployee()">导出
        </el-button>
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.bms_branchemployee_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot-scope="{row}" slot="menu">
        <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="toEdit(row)">编辑
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'bms/branchemployee',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        editBtn: false,
        delBtn: true,
        viewBtn: true,
        selection: true,
        labelWidth: 200,
        searchMenuSpan: 5,
        column: [
          {
            label: '所属门店',
            prop: 'branchId',
            type: 'select',
            filterable: true,
            dicUrl: 'bms/branch/findbranch',
            search: true
          },
          {
            label: '姓名',
            prop: 'name',
            search: true
          },
          // {
          //   label: '编号',
          //   prop: 'no',
          //   search: true
          // },
          {
            label: '手机号',
            prop: 'phone',
            rules: [{
              message: '请输入手机号'
            }]
          },
          {
            label: '身份证号码',
            prop: 'idCard',
            rules: [{
              message: '请输入身份证号码'
            }]
          },
          {
            label: '身份证人头面',
            prop: 'idCardFront',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '身份证背面',
            prop: 'idCardBack',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '家庭住址',
            prop: 'address',
            hide: true,
            rules: [{
              message: '请输入家庭住址'
            }]
          },
          {
            label: '健康证',
            prop: 'healthCertificate',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '健康证图片',
            prop: 'healthCertificateImg',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali'
          },
          {
            label: '紧急联系人',
            prop: 'emergencyContact',
            rules: [{
              message: '请输入紧急联系人'
            }]
          },
          {
            label: '紧急联系电话',
            prop: 'emergencyPhone',
            rules: [{
              message: '请输入紧急联系电话'
            }]
          },
          {
            label: '岗位级别',
            prop: 'level',
            rules: [{
              message: '请输入岗位级别'
            }]
          },
          {
            label: '入职日期',
            prop: 'hireDate',
            hide: true,
            rules: [{
              message: '请输入入职日期'
            }]
          },
          {
            label: '其他证件照，多个,隔开',
            prop: 'otherLicense',
            hide: true,
            type: 'upload',
            listType: 'picture-img',
            oss: 'ali',
            span: 24
          },
          {
            label: '备注',
            prop: 'remark',
            hide: true,
            rules: [{
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.bms_branchemployee_add,
        viewBtn: this.permission.bms_branchemployee_view,
        delBtn: this.permission.bms_branchemployee_delete,
        editBtn: this.permission.bms_branchemployee_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/bms/branchemployeeedit',
        query: { id: row.id }
      })
    },
    toAdd () {
      this.$router.push({ path: '/bms/branchemployeeedit' })
    },
    exportEmployee () {
      this.handleExport('门店员工信息导出')
    }
  }
}
</script>
