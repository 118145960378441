<template>
  <basic-container>
    <div class="center" style="margin-bottom: 15px;">
      <h2>菜谱编辑</h2>
    </div>
    <avue-form :option="option" v-model="form" @submit="handleSubmit">
      <template slot="menuForm">
        <el-button @click="toBack">返回</el-button>
      </template>
    </avue-form>
  </basic-container>
</template>

<script>
import { getCookBookDetail, submit } from '@/api/shop/cookbook'

export default {
  data () {
    return {
      module: 'shop/cookbook',
      form: {},
      search: {},
      selectionList: [],
      option: {
        labelWidth: 120,
        column: [
          {
            label: '标题',
            prop: 'title',
            width: 90,
            rules: [{
              required: true,
              message: '请输入标题'
            }]
          },
          {
            label: '难易程度',
            prop: 'level',
            width: 70,
            type: 'select',
            filterable: true,
            dataType: 'string',
            dicUrl: '/sys/dict/code/cookbook_level',
            rules: [{
              required: true,
              message: '请输入难易程度'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            width: 50,
            type: 'select',
            filterable: true,
            dataType: 'string',
            dicUrl: '/sys/dict/code/cookbook_status',
            rules: [{
              required: true,
              message: '请输入状态'
            }]
          },
          {
            label: '封面图',
            prop: 'thumbnail',
            dataType: 'string',
            type: 'upload',
            width: 90,
            listType: 'picture-img',
            oss: 'ali',
            span: 24
          },
          {
            label: '内容',
            prop: 'content',
            width: 500,
            component: 'AvueUeditor',
            span: 24,
            rules: [{
              required: true,
              message: '请输入内容'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    this.form.id = this.$route.query.id
    if (this.form.id) {
      this.loadData()
    }
  },
  methods: {
    loadData () {
      getCookBookDetail(this.form.id).then(res => {
        this.form = res.data
      })
    },
    handleSubmit () {
      submit(this.form).then(() => {
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
        this.toBack()
      })
    },
    toBack () {
      this.$router.push({ path: '/shop/cookbook' })
    }
  }
}
</script>
