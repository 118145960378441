<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-upload
            class="filter-item"
            :show-file-list="false"
            :action="handleImport()"
            :headers="uploadHeaders()"
            :on-success="uploadSc"
            style="display: inline-block" >
          <el-button type="info" icon="el-icon-top">导 入</el-button>
        </el-upload>
        <el-button type="primary" @click.stop="handleBeefModel()">模板下载</el-button>
        <el-button
            type="success"
            size="small"
            icon="el-icon-bottom"
            @click.prevent="exportBeef"
        >导 出
        </el-button>
        <el-button
            type="success"
            size="small"
            icon="el-icon-right"
            @click.prevent="toCattle">返 回
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import FileSaver from 'file-saver'
import { modelExcel } from '@/api/common'
import { repeatModel, exportExcel } from '@/api/vms/beef'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'vms/beef',
      form: {
        parentId: 0
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        align: 'center',
        indexLabel: '序号',
        selection: true,
        delBtn: false,
        searchMenuSpan: 6,
        searchSpan: 6,
        column: [
          {
            label: '所属供应商',
            prop: 'vendorId',
            width: 180,
            type: 'select',
            filterable: true,
            dicUrl: '/vms/vendor/dict',
            addDisabled: true,
            editDisabled: true
          },
          {
            label: '耳标',
            prop: 'cattleId',
            searchMenuSpan: 6,
            width: 100,
            type: 'select',
            placeholder: '请选择耳标号',
            filterable: true,
            search: true,
            dicUrl: '/vms/cattle/dict',
            addDisabled: true,
            editDisabled: true
          },
          {
            label: '大块肉编号',
            prop: 'beefCode',
            searchMenuSpan: 6,
            width: 100,
            search: true,
            placeholder: '请输入编号',
            searchLabelWidth: 100
          },
          {
            label: '品名',
            width: 100,
            prop: 'materialName'
          },
          {
            label: '物料号',
            prop: 'materialId',
            type: 'select',
            filterable: true,
            dicUrl: '/vms/beef/material',
            rules: [{
              message: '请选择编号',
              trigger: 'click'
            }]
          },
          {
            label: '入库重量',
            prop: 'inWeight',
            rules: [{
              required: true,
              message: '请输入入库重量'
            }]
          },
          {
            label: '出库重量',
            prop: 'outWeight',
            rules: [{
              required: true,
              message: '请输入出库重量'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  created () {
    // 赋值查询默认条件
    this.search.cattleId = this.$route.query.cattleNum
    this.option.addBtn = false
    this.option.viewBtn = this.permissionList.viewBtn
    this.option.editBtn = false
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.vms_beef_add,
        viewBtn: this.permission.vms_beef_view,
        delBtn: this.permission.vms_beef_delete,
        editBtn: this.permission.vms_beef_edit
      }
    }
  },
  methods: {
    uploadSc (res) {
      if (res.data.length > 0) {
        this.$message({ message: '操作失败,耳标号或大块肉编号错误,请更正', type: 'error' })
        // 下载导入重复数据
        const stringifyClass1 = JSON.stringify(res.data)
        repeatModel(stringifyClass1).then(ps => {
          FileSaver.saveAs(ps, '数据错误请更正.xlsx')
        })
      } else {
        this.$message({ message: '操作成功', type: 'success' })
        this.onLoad(this.page)
      }
    },
    toCattle () {
      this.$router.push({
        path: '/vms/cattle'
      })
    },
    exportBeef () {
      exportExcel(this.search).then(res => {
        FileSaver.saveAs(res, '大块肉信息导出.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      }
      )
    },
    handleBeefModel () {
      modelExcel(this.module, this.page.currentPage, this.page.pageSize, this.search).then(res => {
        FileSaver.saveAs(res, '大肉过磅模板.xlsx')
        this.$message({
          type: 'success',
          message: '操作成功!'
        })
      })
    }
  }
}
</script>
