<template>
  <basic-container>
    <avue-crud
            :option="option"
            :defaults.sync="defaults"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'shop/giftcard',
      defaults: {},
      form: {},
      search: {},
      page: {
        pageSize: 50,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        menuWidth: 140,
        selection: true,
        searchMenuSpan: 5,
        column: [
          {
            label: '账号',
            prop: 'username',
            search: true,
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入账号'
            }]
          },
          {
            label: '类型',
            prop: 'type',
            value: 1,
            type: 'radio',
            span: 24,
            search: true,
            dicData: [{
              label: '现金卡',
              value: 1
            }, {
              label: '实物卡',
              value: 2
            }],
            rules: [{
              required: true,
              message: '请输入类型'
            }]
          },
          {
            label: '关联商品',
            prop: 'skuName',
            type: 'select',
            filterable: true,
            dicUrl: '/shop/goods/goodsList',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入关联商品'
            }]
          },
          {
            label: '单价',
            prop: 'skuPrice',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入单价'
            }]
          },
          {
            label: '余额',
            prop: 'money',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入余额'
            }]
          },
          {
            label: '状态',
            prop: 'status',
            type: 'select',
            addDisplay: false,
            dicData: [{
              label: '未绑定',
              value: 0
            }, {
              label: '已绑定',
              value: 1
            }, {
              label: '使用完',
              value: 2
            }],
            rules: [{
              required: true,
              message: '请输入状态'
            }]
          },
          {
            label: '所属人',
            prop: 'nickName',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入所属人'
            }]
          },
          {
            label: '所属门店',
            prop: 'storeId',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入所属门店'
            }]
          },
          {
            label: '绑定时间',
            prop: 'bindTime',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入绑定时间'
            }]
          },
          {
            label: '过期时间',
            prop: 'expireDate',
            addDisplay: false,
            rules: [{
              required: true,
              message: '请输入过期时间'
            }]
          },
          {
            label: '有效天数',
            prop: 'expirationDays',
            type: 'number',
            span: 24,
            rules: [{
              required: true,
              message: '请输入有效天数'
            }]
          },
          {
            label: '卡数量',
            prop: 'count',
            type: 'number',
            rules: [{
              required: true,
              message: '请输入数量'
            }]
          },
          {
            label: '使用规则',
            prop: 'useRule'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.shop_giftcard_add,
        viewBtn: this.permission.shop_giftcard_view,
        delBtn: this.permission.shop_giftcard_delete,
        editBtn: this.permission.shop_giftcard_edit
      }
    }
  },
  watch: {
    'form.type' (val) {
      if (val === 1) {
        console.log(this.form)
        this.defaults.skuName.addDisplay = false
        this.defaults.skuPrice.addDisplay = false
        this.defaults.money.addDisplay = true
      } else {
        this.defaults.skuName.addDisplay = true
        this.defaults.skuPrice.addDisplay = true
        this.defaults.money.addDisplay = false
      }
    }
  },
  methods: {
  }
}
</script>
